import { Component, OnInit, OnDestroy } from '@angular/core';
import { OnUser } from 'on-shared/_models/OnUser';
import { NbIconLibraries } from '@nebular/theme';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { MonitoringService } from 'on-shared/_services/monitoring.service';
import { UserState } from 'on-shared/stores/user/user.state';
import { BadgesData } from 'on-shared/_models/BadgesData';
import { Select, Store } from '@ngxs/store';
import { BadgesState } from 'on-shared/stores/badges/badges.state';
import { BadgesAction } from 'on-shared/stores/badges/badges.action';
import { SubscriptionService } from 'on-shared/stores/subscription/subscription.service';
import { MenuItem } from './layout/_models/MenuItem';
import { SideBarService } from 'on-layout/_services/side-bar.service';
import { NotificationBannerService } from 'on-layout/_services/notification-banner.service';
import { TopBarService } from 'on-layout/_services/top-bar.service';
import { LANGUAGE_STORAGE_KEY } from 'on-common/constants';
import { environment } from '../environments/environment';

@Component({
  selector: 'on-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private user: OnUser;

  private destroy$ = new Subject<void>();
  @Select(UserState.currentUser) $currentUser: Observable<OnUser>;

  @Select(BadgesState.badgesData) badgesData: Observable<BadgesData>;

  constructor(
    private store: Store,
    private sideBarService: SideBarService,
    private notificationBannerService: NotificationBannerService,
    private iconLibraries: NbIconLibraries,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private translate: TranslateService,
    private topBarService: TopBarService,
    private monitoringService: MonitoringService,
  ) {
    this.iconLibraries.registerFontPack('solid', { packClass: 'fas' });
    this.iconLibraries.registerFontPack('regular', { packClass: 'far' });
    this.iconLibraries.registerFontPack('light', { packClass: 'fal' });
    this.iconLibraries.registerFontPack('duotone', { packClass: 'fad' });
    this.iconLibraries.registerFontPack('brands', { packClass: 'fab' });
    this.iconLibraries.setDefaultPack('solid');
  }

  ngOnInit() {
    this.$currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentUser) => {
        if (currentUser) {
          this.implementAppWithUser(currentUser);
        } else {
          this.user = null;
          this.store.dispatch(new BadgesAction.StopReloadingBadges());
        }
      });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.monitoringService.startNavigationEvent(event.url);
      } else if (event instanceof NavigationEnd) {
        this.monitoringService.endNavigationEvent(event.url);
        this.monitoringService.logPageView();
      }
    });

    this.topBarService.languageSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (codeLang) => {
          if (codeLang && this.translate.currentLang !== codeLang) {
            localStorage.setItem(LANGUAGE_STORAGE_KEY, codeLang);
            window.location.reload();
          }
        },
      });

  }

  ngOnDestroy() {
    this.store.dispatch(new BadgesAction.StopReloadingBadges());

    this.destroy$.next();
    this.destroy$.complete();
  }

  implementAppWithUser(user) {
    if (
      !this.user ||
      this.user?.Id !== user?.Id ||
      this.user?.CompanyId !== user?.CompanyId ||
      this.user?.Role !== user?.Role
    ) {
      this.monitoringService.setUserId(
        user.Id,
        user.CompanyId ? user.CompanyId : null,
      );
      this.user = user;

      this.store.dispatch(new BadgesAction.AutoReloadingBadges());

      // Badges
      this.badgesData.pipe(takeUntil(this.destroy$)).subscribe(response => {
        this.sideBarService.sibarTree.next(this.computeSidebarTree(user, response));
      });

      this.subscriptionService
        .needToRenewSubscriptionFomUser(user)
        .subscribe((renew) => {
          const renewSubscription = renew;
          if (renewSubscription[0]) {
            this.notificationBannerService.notificationToDisplay.next(
              this.subscriptionService.getSubscriptionNotificationToDisplay(
                user,
                renewSubscription[1],
              ),
            );
          }
        });
    }
  }

  private computeSidebarTree(connectedUser: OnUser, badgesData?: BadgesData): MenuItem[] {
    return [
      {
        title: this.translate.instant('menu.dashboard'),
        link: `/dashboard`,
        icon: `fa-tachometer-alt`,
      },
      {
        title: this.translate.instant('menu.send-expense'),
        link: `/expenses/send`,
        icon: `fa-upload`,
      },
      {
        title: this.translate.instant('menu.create-expense'),
        link: `/expenses/create`,
        queryParams: { type: 'km' },
        icon: `fa-road`,
      },
      {
        title: this.translate.instant('menu.expenses'),
        link: `/expenses`,
        icon: `fa-receipt`,
        badges: badgesData ? badgesData.ExpensesToBeVerified : 0,
        badgeColor: '#6c757d'
      },
      {
        title: this.translate.instant('menu.reports'),
        link: `/reports`,
        icon: `fa-archive`,
        badges: badgesData ? badgesData.ReportsRefused : 0,
        badgeColor: 'red'
      },
      {
        title: this.translate.instant('menu.mysignatures'),
        link: `/company/mysignatures`,
        icon: `fa-file-signature`,
        hidden: !(
          connectedUser.CompanyId
          && connectedUser.HasEmployees
        ),
        badges: badgesData ? badgesData.ReportsToSign : 0,
        badgeColor: '#6c757d'
      },
      {
        title: this.translate.instant('menu.accounting', { appName: environment.appName }),
        hidden: !(
          connectedUser.CompanyId &&
          (connectedUser.Role === 'ADMIN' || connectedUser.Role === 'ACC')
        ),
        icon: `fa-calculator`,
        expanded: true,
        children: [
          {
            title: this.translate.instant('menu.tobeexported'),
            link: `/company/validatedexpenses`,
            icon: `fa-file-export`,
            badges: badgesData ? badgesData.ReportsToBeExported : 0,
            badgeColor: '#6c757d'
          },
          {
            title: this.translate.instant('menu.exports'),
            link: `/company/exports`,
            icon: `fa-check`,
            badges: badgesData ? badgesData.AccountantExportsNotTreated : 0,
            badgeColor: '#6c757d'
          },
          {
            title: this.translate.instant('menu.parameters'),
            link: `/company/accountant-settings`,
            icon: `fa-cogs`,
          },
        ],
      },
      {
        title: this.translate.instant('menu.company'),
        hidden: !(
          (connectedUser.CompanyId && connectedUser.Role === 'ADMIN') ||
          !connectedUser.CompanyId
        ),
        icon: `fa-building`,
        expanded: true,
        children: [
          {
            title: this.translate.instant('menu.parameters'),
            link: `/company`,
            icon: `fa-cogs`,
            hidden: !(
              connectedUser.CompanyId && connectedUser.Role === 'ADMIN'
            ),
          },
          {
            title: this.translate.instant('menu.create'),
            link: `/company`,
            icon: `fa-plus`,
            hidden: !!connectedUser.CompanyId,
          },
          {
            title: this.translate.instant('menu.licenses'),
            link: `/company/licenses`,
            icon: `fa-euro-sign`,
            hidden: !(
              connectedUser.CompanyId && connectedUser.Role === 'ADMIN'
            ),
          },
          {
            title: this.translate.instant('menu.statistics'),
            link: `/company/statistics`,
            icon: 'fa-chart-line',
            hidden: !(
              connectedUser.CompanyId && connectedUser.Role === 'ADMIN'
            ),
          }
        ],
      },
      {
        title: this.translate.instant('menu.accountant-expert'),
        hidden: !connectedUser.IsAccountantExpert ||
          (connectedUser.Role !== 'ADMIN' && connectedUser.Role !== 'ACC'),
        icon: 'fa-handshake',
        link: '/accountant_expert/companies'
      },
      {
        title: this.translate.instant('menu.account', { appName: environment.appName }),
        link: `/account`,
        icon: `fa-credit-card`,
        hidden: !(
          !connectedUser.License &&
          !connectedUser.CompanyId
        ),
      },
      {
        title: this.translate.instant('menu.support'),
        icon: `fa-question`,
        expanded: false,
        children: [
          {
            title: this.translate.instant('menu.documentation'),
            icon: 'fa-book',
            url: 'https://fastfees.ch/documentation/',
            target: '_blank'
          },
          {
            title: this.translate.instant('menu.video'),
            icon: 'fa-play-circle',
            url: 'https://fastfees.ch/video/',
            target: '_blank'
          },
          {
            title: this.translate.instant('menu.contact-us'),
            icon: 'fa-envelope',
            link: `/support`
          }
        ]
      },
      {
        title: this.translate.instant('menu.share', { appName: environment.appName }),
        link: `/share`,
        icon: `fa-share`,
      },
    ];
  }
}
