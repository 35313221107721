<div *ngIf="user">
  <!-- <PERSONNAL INFORMATIONS> -->
  <on-two-columns-display [title]="'shared.global-settings.title' | translate">
    <div content>
      <form [formGroup]="personnalForm">

        <div class="form-group">
          <label for="email">{{'shared.global-settings.email' | translate}}</label>
          <input type="email" class="form-control" placeholder="exemple@email.com" id="email"
            formControlName="ContactEmail">
        </div>


        <!-- <div class="alert alert-danger" role="alert" *ngIf="!personnalForm.controls['email'].valid && (personnalForm.controls['email'].touched)">
          <div [hidden]="!personnalForm.controls['email'].errors.required">
            L'email est requis.
          </div>
          <div [hidden]="!personnalForm.controls['email'].errors.email">
            L'email est invalide.
          </div>
        </div> -->

        <div class="form-group">
          <label for="firstname">{{'shared.global-settings.first-name' | translate}}</label>
          <input type="text" class="form-control" placeholder="Prénom" id="firstname" formControlName="FirstName">
        </div>
        <div class="alert alert-danger" role="alert"
          *ngIf="!personnalForm.controls['FirstName'].valid && (personnalForm.controls['FirstName'].touched)">
          <div [hidden]="!personnalForm.controls['FirstName'].errors.required">
            {{'shared.global-settings.first-name-required' | translate}}
          </div>
        </div>

        <div class="form-group">
          <label for="lastname">{{'shared.global-settings.last-name' | translate}}</label>
          <input type="text" class="form-control" placeholder="Nom" id="lastname" formControlName="LastName">
        </div>
        <div class="alert alert-danger" role="alert"
          *ngIf="!personnalForm.controls['LastName'].valid && (personnalForm.controls['LastName'].touched)">
          <div [hidden]="!personnalForm.controls['LastName'].errors.required">
            {{'shared.global-settings.last-name-required' | translate}}
          </div>
        </div>

        <div class="form-group">
          <label for="phonenumber">{{'shared.global-settings.phone' | translate}}</label>
          <input type="tel" pattern="\+?\d{2,3}\d{8}" placeholder="ex: +41512345678 / 0213456789"
            class="form-control no-spinner" id="phonenumber" formControlName="PhoneNumber">
        </div>
        <div class="alert alert-danger" role="alert"
          *ngIf="!personnalForm.controls['PhoneNumber'].valid && (personnalForm.controls['PhoneNumber'].touched)">
          <div [hidden]="!personnalForm.controls['PhoneNumber'].errors.required">
            {{'shared.global-settings.phone-required' | translate}}
          </div>
        </div>
      </form>
    </div>
  </on-two-columns-display>
  <!-- </PERSONNAL INFORMATIONS> -->

  <!--<ADDRESS> -->
  <on-two-columns-display [title]="'shared.global-settings.address' | translate" *ngIf="!user.CompanyId">
    <div content>
      <form [formGroup]="addressForm">
        <div class="row">
          <div class="col">
            <label for="street1">{{'shared.global-settings.street1' | translate}}</label>
            <input type="text" class="form-control" id="street1" formControlName="Street1">
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="alert alert-danger" role="alert"
              *ngIf="!addressForm.controls['Street1'].valid && (addressForm.controls['Street1'].touched)">
              <div [hidden]="!addressForm.controls['Street1'].errors.required">
                {{'shared.global-settings.street1-required' | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label for="street2">{{'shared.global-settings.street2' | translate}}</label>
            <input type="text" class="form-control" id="street2" formControlName="Street2">
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label for="cityname">{{'shared.global-settings.city' | translate}}</label>
            <input type="text" class="form-control" id="cityname" formControlName="City">
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="alert alert-danger" role="alert"
              *ngIf="!addressForm.controls['City'].valid && (addressForm.controls['City'].touched)">
              <div [hidden]="!addressForm.controls['City'].errors.required">
                {{'shared.global-settings.city-required' | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label for="zip">{{'shared.global-settings.zip-code' | translate}}</label>
            <input type="number" class="form-control" id="zip" formControlName="Zip">
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="alert alert-danger" role="alert"
              *ngIf="!addressForm.controls['Zip'].valid && (addressForm.controls['Zip'].touched)">
              <div [hidden]="!addressForm.controls['Zip'].errors.required">
                {{'shared.global-settings.zip-code-required' | translate}}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </on-two-columns-display>
  <!--</ADDRESS> -->

  <!-- <PROFESSIONAL INFORMATIONS> -->
  <on-two-columns-display *ngIf="currentCompany$ | async as company"
    [title]="'shared.global-settings.company-info' | translate">
    <div content>
      <form [formGroup]="employeeCompanyForm">
        <div class="form-group">
          <label for="identifier">{{'shared.global-settings.identifier' | translate}}</label>
          <input type="text" class="form-control" id="identifier" formControlName="Identifier">
        </div>

        <div class="form-group">
          <label for="employeecategory">{{'shared.global-settings.category' | translate}}</label>
          <on-employee-category-select id="employeecategory" formControlName="EmployeeCategory" [allowAll]="false">
          </on-employee-category-select>
        </div>

        <div class="form-group">
          <label for="organisationunit">{{'shared.global-settings.organization-unit' | translate}}</label>
          <input type="text" class="form-control" id="organisationunit" formControlName="OrganizationUnit">
        </div>

        <div class="form-group">
          <label for="otherinfo1">{{'shared.global-settings.info1' | translate}}</label>
          <input type="text" class="form-control" id="otherinfo1" formControlName="OtherInfo1">
        </div>

        <div class="form-group">
          <label for="otherinfo2">{{'shared.global-settings.info2' | translate}}</label>
          <input type="text" class="form-control" id="otherinfo2" formControlName="OtherInfo2">
        </div>

        <div class="form-group">
          <label for="otherinfo3">{{'shared.global-settings.info3' | translate}}</label>
          <input type="text" class="form-control" id="otherinfo3" formControlName="OtherInfo3">
        </div>

        <div class="form-group">
          <label for="otherinfo4">{{'shared.global-settings.info4' | translate}}</label>
          <input type="text" class="form-control" id="otherinfo4" formControlName="OtherInfo4">
        </div>
      </form>
    </div>
  </on-two-columns-display>
  <!-- </PROFESSIONAL INFORMATIONS> -->

  <!-- <VEHICLES> -->
  <on-two-columns-display [title]="'shared.global-settings.vehicles' | translate">
    <div content class="h-100">
      <div *ngIf="isVehiclesLoading">
        <on-loading></on-loading>
      </div>
      <div class="row h-100 d-flex align-items-center" *ngIf="!isVehiclesLoading">
        <div class="col-12">
          <div class="form-group">
            <label>{{'vehicles.distance-home-to-work' | translate}}</label>
            <input type="number" class="form-control" [(ngModel)]="user.DistanceHomeToWork" [disabled]="disabled">
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="mileageratio">{{'shared.global-settings.mileage-ratio' | translate: { symbol: (user.Currency &&
              user.Currency.Symbol) ? user.Currency.Symbol : null} }}</label>
            <input class="form-control" [(ngModel)]="user.MileageRatio" [disabled]="disabled" />
          </div>
        </div>
        <div class="col-6 vehicle-item" *ngFor="let vehicle of vehicles">
          <div class="row border-rounded" [ngClass]="{'vehicle-disabled': vehicle.disabled}">
            <div class="col d-flex align-items-center justify-content-center clickable" (click)="EditVehicle(vehicle)">
              {{ vehicle.Name }}</div>
            <div class="col-2 pe-0 d-flex justify-content-end">
              <button class="btn btn-rounded-right btn-delete-vehicle" (click)="DeleteVehicle(vehicle)"
                *ngIf="!vehicle.disabled && !disabled"><span>&times;</span></button>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-center" *ngIf="!disabled">
          <button class="btn btn-sm btn-secondary" (click)="AddVehicle()">
            <nb-icon icon="fa-plus" style="font-size: 1rem;"></nb-icon>{{'vehicles.add-vehicle-btn' | translate}}
          </button>
        </div>
      </div>
    </div>
  </on-two-columns-display>
  <!-- </VEHICLES> -->

  <!-- <SOCIAL LINK> -->
  <on-two-columns-display [title]="'shared.global-settings.social' | translate" *ngIf="!user.CompanyId">
    <div content>
      <form [formGroup]="personnalForm">
        <label for="facebook">{{'shared.global-settings.fb' | translate}}</label>
        <input type="text" class="form-control" formControlName="Facebook"
          [placeholder]="'shared.global-settings.fb-placeholder' | translate" id="facebook">

        <label for="linkedin">{{'shared.global-settings.LinkedIn' | translate}}</label>
        <input type="text" class="form-control" formControlName="LinkedIn"
          [placeholder]="'shared.global-settings.LinkedIn-placeholder' | translate" id="linkedin">
      </form>
    </div>
  </on-two-columns-display>
  <!-- </SOCIAL LINK> -->

  <!-- <ACCOUNTING SETTINGS> -->
  <on-two-columns-display *ngIf="currentCompany$ | async as company"
    [title]="'shared.global-settings.accounting-params' | translate">
    <div content>
      <form [formGroup]="employeeCompanyForm">
        <div class="form-group">
          <label for="personalAccount">{{'shared.global-settings.info5' | translate}}</label>
          <input type="text" class="form-control" id="personalAccount" formControlName="ThirdPartyAccount">
        </div>
        <div class="form-group mt-2" *ngIf="company.UseRestaurantTicket">
          <div class="custom-control custom-switch custom-switch-md">
            <input type="checkbox" class="custom-control-input" id="restaurant-ticket"
              formControlName="HasRestaurantTicket">
            <label class="custom-control-label" for="restaurant-ticket">{{'shared.global-settings.has-restaurant-ticket'
              | translate}}</label>
          </div>
        </div>
        <div class="form-group mt-2">
          <div class="custom-control custom-switch custom-switch-md">
            <input type="checkbox" class="custom-control-input" id="per-diem" formControlName="HasPerDiem">
            <label class="custom-control-label" for="per-diem">{{'shared.global-settings.has-per-diem'
              | translate}}</label>
          </div>
        </div>
        <div class="form-group mt-2">
          <div class="custom-control custom-switch custom-switch-md">
            <input type="checkbox" class="custom-control-input" id="pro-card" formControlName="HasProfessionalCard">
            <label class="custom-control-label" for="pro-card">{{'shared.global-settings.has-professional-card'
              | translate}}</label>
          </div>
        </div>
      </form>
      <div class="form-group" *ngFor="let plan of (userAccountingPlans$ | async)">
        <label for="country">{{plan.Name}}</label>
        <select class="form-control" [class.unassigned-option]="!selectedUserAccountingPlans[plan.Id]"
          [(ngModel)]="selectedUserAccountingPlans[plan.Id]" *ngIf="selectedUserAccountingPlans" [disabled]="disabled">
          <option [ngValue]="undefined"><em class="unassigned-option">{{'shared.global-settings.unassigned-field' |
              translate}}</em></option>
          <option [ngValue]="val.Id" class="assigned-option" *ngFor="let val of plan.PossibleValues">{{val.Name}}
          </option>
        </select>
      </div>
    </div>
  </on-two-columns-display>
  <!-- </ACCOUNTING SETTINGS> -->

  <!-- <ONEXPENSE SETTINGS> -->
  <on-two-columns-display [title]="'shared.global-settings.on-params' | translate : {appName:appName}">
    <div content>
      <form [formGroup]="onexpenseForm">

        <label for="country">{{'shared.global-settings.default-country' | translate}}</label>
        <on-country-select formControlName="Country"></on-country-select>

        <!-- <label for="currency">{{'shared.global-settings.currency' | translate}}</label>
        <on-currency-select formControlName="Currency"></on-currency-select> -->
      </form>
    </div>
  </on-two-columns-display>
  <!-- </ONEXPENSE SETTINGS> -->


  <div class="row" *ngIf="!disabled">
    <div class="col">
      <button class="btn btn-primary" (click)="SaveInformations()">{{'shared.global-settings.save' |
        translate}}</button>
    </div>
  </div>
</div>