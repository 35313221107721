import { Component, OnInit } from '@angular/core';
import { AccountService } from './../_services/account.service';
import { IRegisterModel } from './../_models/RegisterModel';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'on-common/_services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'projects/onexpense/src/environments/environment';
import { GoogleTagService } from 'on-shared/_services/google-tag.service';

@Component({
  selector: 'on-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
})
export class RegisterPageComponent implements OnInit {
  isLoading = false;
  token: string;
  email: string;

  constructor(
    private accountService: AccountService,
    private toastService: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private gtagService: GoogleTagService
  ) { }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.queryParams['token'];
    this.email = this.activatedRoute.snapshot.queryParams['email'];
  }

  Register(registermodel?: IRegisterModel) {
    this.isLoading = true;
    this.accountService
      .Register(
        registermodel.UserName,
        registermodel.Password,
        registermodel.Country,
        registermodel.Currency,
        registermodel.LanguageCode,
        this.token,
      )
      .subscribe(
        (response) => {
          if (response.IsSuccess) {
            this.gtagService.event('register_success', 'register', '');
            this.toastService.success(
              this.translate.instant('register.welcome', { appName: environment.appName }),
              this.translate.instant('register.register-success-title')
            );
            this.accountService
              .LogIn(registermodel.UserName, registermodel.Password)
              .subscribe((res) => {
                if (this.activatedRoute.snapshot.queryParams['returnUrl']) {
                  this.router.navigateByUrl(
                    this.activatedRoute.snapshot.queryParams['returnUrl'],
                  );
                } else {
                  this.router.navigate(['/expenses']);
                }
              });
          } else {
            this.toastService.error(
              this.translate.instant('register.register-error')
            );
            this.isLoading = false;
          }
        },
        (error) => {
          this.toastService.error(
            this.translate.instant('register.register-error')
          );
          this.isLoading = false;
        },
      );
  }
}
