import { Subscription, Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
  Input,
} from '@angular/core';
import { Company } from 'on-shared/_models/Company';
import { CompanyService } from 'on-shared/stores/company/company.service';
import { Store, Select } from '@ngxs/store';
import { CompanyAction } from 'on-shared/stores/company/company.action';
import { CompanyState } from 'on-shared/stores/company/company.state';
import { takeUntil } from 'rxjs/operators';
import { UserState } from 'on-shared/stores/user/user.state';
import { OnUser } from 'on-shared/_models/OnUser';
import { ToastService } from 'on-common/_services/toast.service';
import { environment } from 'projects/onexpense/src/environments/environment';

@Component({
  selector: 'on-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss'],
})
export class CreateCompanyComponent implements OnInit, OnDestroy {
  @Output() companyCreated = new EventEmitter();
  @Output() back = new EventEmitter(); // Used for business unit only

  @Input() isAllowed = false; // Used for business unit
  currentCompany: Company;

  companyForm: UntypedFormGroup;
  addressForm: UntypedFormGroup;
  companyGroupForm: UntypedFormGroup;

  isLoading = false;
  hasAlreadyPro = false;
  userSubscription: Subscription;

  appName = environment.appName;

  @Select(CompanyState.current) company$: Observable<Company>;
  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;
  destroy$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private companyService: CompanyService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private store: Store,
  ) { }

  ngOnInit() {
    this.companyForm = this.fb.group({
      Name: ['', Validators.required],
      AdminEmail: ['', Validators.required],
      AdminPhone: ['', Validators.required],
      SIRET: [''],
      Country: [
        {
          Id: 0,
          ISOCode: environment.defaultCountry,
          LocalName: 'Default',
          FlagFileName: '',
          PrettyCode: '',
        },
        Validators.required,
      ],
      Currency: [
        { Id: 0, ISOCode: environment.defaultCurrency, Symbol: '', Name: 'Default' },
        Validators.required,
      ],
    });

    this.addressForm = this.fb.group({
      Street1: [''],
      Street2: [''],
      City: [''],
      Zip: [''],
    });

    this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (
        user.License &&
        user.License.ProductTypeCode === 'PRO'
      ) {
        this.hasAlreadyPro = true;
      } else {
        this.hasAlreadyPro = false;
      }
    });

    if (this.isAllowed) {
      this.store.dispatch(new CompanyAction.LoadCompaniesIfNeeded());
      this.loadCurrentCompany();
    }
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  loadCurrentCompany() {
    this.isLoading = true;

    this.company$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response) {
        this.currentCompany = response;
        this.isLoading = false;

        let companyGroupName = this.currentCompany.CompanyGroupName;
        if (!companyGroupName) {
          companyGroupName = '';
        }

        this.companyGroupForm = this.fb.group({
          Name: [
            {
              value: companyGroupName,
              disabled: companyGroupName === '' ? false : true,
            },
            Validators.required,
          ],
        });
      }
    });
  }

  createCompany() {
    for (const controlKey in this.companyForm.controls) {
      if (this.companyForm.controls[controlKey]) {
        this.companyForm.controls[controlKey].markAsTouched();
      }
    }

    if (this.isAllowed) {
      for (const controlKey in this.companyGroupForm.controls) {
        if (this.companyGroupForm.controls[controlKey]) {
          this.companyGroupForm.controls[controlKey].markAsTouched();
        }
      }
    }

    if (this.companyForm.valid && this.addressForm.valid) {
      const company = this.companyForm.value;
      company.Address = this.addressForm.value;

      if (this.isAllowed) {
        if (!this.companyGroupFormIsValid()) {
          return;
        }
        company.CompanyGroupName = this.companyGroupForm.get('Name').value;
        company.CompanyGroupId = this.currentCompany.CompanyGroupId;
      }

      this.isLoading = true;

      this.companyService.Create(company).subscribe(
        (response) => {
          if (response.IsSuccess) {
            if (this.isAllowed) {
              this.cancelCreationCompany();
            }
            this.companyCreated.emit();
          } else {
            this.toastService.error(
              this.translateService.instant('company.create.cannot-create'),
            );
          }

          this.store.dispatch(new CompanyAction.LoadCompanies());

          this.isLoading = false;
        },
        (error) => {
          this.toastService.error(
            this.translateService.instant('company.create.cannot-create'),
          );
          this.isLoading = false;
        },
      );
    } else {
      this.toastService.warning(
        this.translateService.instant('company.create.check-fields'),
      );
    }
  }

  companyGroupFormIsValid(): boolean {
    return !(
      this.companyGroupForm.get('Name').value == null ||
      this.companyGroupForm.get('Name').value === ''
    );
  }

  cancelCreationCompany() {
    this.back.emit();
  }
}
