<form [formGroup]="registerForm">

  <div class="row">
    <div class="col">
      <label for="email">Email</label>
      <input type="email" class="form-control" placeholder="exemple@email.com" id="email" formControlName="UserName">
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="alert alert-danger" role="alert" *ngIf="!registerForm.controls['UserName'].valid && (registerForm.controls['UserName'].touched)">
        <div [hidden]="!registerForm.controls['UserName'].errors.required">
          Email est requis
        </div>
        <div [hidden]="!registerForm.controls['UserName'].errors.email">
          Email invalide
        </div>
      </div>
    </div>
  </div>

  <!--PASSWORD -->
  <div class="row">
    <div class="col">
      <label for="password">Mot de passe</label>
      <input type="password" id="password" class="form-control" formControlName="Password">
      <on-required-field-display [field]="registerForm.controls['Password']"></on-required-field-display>
    </div>
  </div>

  <!--CONFIRMATION -->
  <div class="row">
    <div class="col">
      <label for="confirmation">Confirmation du mot de passe</label>
      <input type="password" id="confirmation" class="form-control" formControlName="ConfirmPassword">
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="alert alert-danger" *ngIf="(!registerForm.controls['ConfirmPassword'].valid || registerForm.errors?.isPasswordDifferent) && (registerForm.controls['ConfirmPassword'].touched)">
        <div *ngIf="registerForm.controls['ConfirmPassword'].errors?.required">
          Confirmation requise !
        </div>

        <div *ngIf="registerForm.errors?.isPasswordDifferent">
          La confirmation est différente du mot de passe !
        </div>
      </div>
    </div>
  </div>

  <!--COUNTRY -->
  <div class="row">
    <div class="col">
      <label for="country">Pays</label>
      <on-country-select formControlName="Country"></on-country-select>
      <on-required-field-display [field]="registerForm.controls['Country']"></on-required-field-display>
    </div>
  </div>

  <!--CURRENCY -->
  <div class="row">
    <div class="col">
      <label for="currency">Monnaie</label>
      <on-currency-select formControlName="Currency"></on-currency-select>
      <on-required-field-display [field]="registerForm.controls['Currency']"></on-required-field-display>
    </div>
  </div>
  <br>
  <!--CGU / CGV-->
  <div class="form-check text-center">
    <input class="form-check-input me-2" type="checkbox" value="" id="cgucheck" formControlName="cgu">
    <label class="form-check-label" for="cgucheck">
      Accepter les <a target="_blank" href="https://fastfees.ch/cgu">CGU / CGV</a>
    </label>
  </div>

  <div class="row">
    <div class="col">
      <div class="alert alert-danger" *ngIf="!registerForm.controls['cgu'].valid && (registerForm.controls['cgu'].touched)">
        <div [hidden]="!registerForm.controls['cgu'].errors.required">
          Veuillez accepter les <a target="_blank" href="https://fastfees.ch/cgu">CGU / CGV</a>
        </div>
      </div>
    </div>
  </div>
</form>

<br>
<div class="row">
  <div class="col text-center">
    <button class="btn btn-primary" (click)='Register()'>S'enregistrer</button>
  </div>
</div>
<div class="row">
  <div class="col text-center small-text">
    <span for="createAccount">Déjà un compte ?</span>
    <br>
    <a id="createAccount" routerLinkActive="active" routerLink="/login" [queryParams]="route.snapshot.queryParams">Connectez-vous</a>
  </div>
</div>