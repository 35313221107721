import { StripeProduct } from './../../_models/StripeProduct';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { PaymentService } from '../../_services/payment.service';
import { StripePrice } from '../../_models/StripePrice';
import { Router } from '@angular/router';
import { CheckoutDataKey, UpdateSubscriptionDataKey } from '../../constants';
import { CheckoutModel } from '../../_models/CheckoutModel';
import { Subject } from 'rxjs';
import { StripeSubscription } from '../../_models/StripeSubscription';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { StripeService } from 'ngx-stripe';
import { ProductType } from 'on-common/_models/ProducType';

marker('day');
marker('week');
marker('month');
marker('year');

@Component({
  selector: 'on-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss']
})
export class StripePaymentComponent implements OnInit, OnDestroy {

  @Input() currentSubscription: StripeSubscription;

  products: StripeProduct[];
  selectedProduct: StripeProduct;
  basicProduct: StripeProduct;
  premiumProduct: StripeProduct;
  selectedPrice: StripePrice;
  quantity = 1;
  totalPrice: number;
  totalPriceTtc: number;

  destroy$ = new Subject<void>();

  isLoading: boolean;

  constructor(
    private paymentService: PaymentService,
    private router: Router
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.paymentService.GetAllProducts().subscribe(response => {
      if (response.IsSuccess) {
        // Set products and order them from lower price to upper price
        this.products = response.Result.filter(p => p.IsLicense).sort((a, b) => {
          const monthPriceA = a.Prices.find(p => p.Interval === 'month');
          const monthPriceB = b.Prices.find(p => p.Interval === 'month');
          if (monthPriceA.Price > monthPriceB.Price) {
            return 1;
          } else {
            return -1;
          }
        });
        for (const prod of this.products) {
          if (this.paymentService.IsBasic(prod)) {
            this.basicProduct = prod;
          } else if (this.paymentService.IsPremium(prod)) {
            this.premiumProduct = prod;
          }
        }
        if (!this.currentSubscription) {
          this.selectedProduct = null;
        } else {
          this.quantity = this.currentSubscription.Quantity;
          this.selectedProduct = this.products.find(p =>
            p.Prices.find(price =>
              price.Id === this.currentSubscription.RecurringPrice.Id) != null);

          if (!this.selectedProduct) {
            // Unable to find the product, that may mean that the product has been archived
            // Try to get the precise price
            this.paymentService.GetPriceDetails(this.currentSubscription.RecurringPrice.Id).subscribe(price => {
              if (price.IsSuccess) {
                this.selectedProduct = price.Result.Product;
                // Prevent infinite loop now that we stored the product
                price.Result.Product = null;
                this.selectedProduct.Prices = [price.Result];
                this.selectedPrice = price.Result;
                if (!this.products.find(p => p.Id === this.selectedProduct.Id)) {
                  this.products.unshift(this.selectedProduct);
                }
              }
            });
          } else {
            this.selectedPrice = this.selectedProduct.Prices.find(price => price.Id === this.currentSubscription.RecurringPrice.Id);
            const selectedProductIndex = this.products.indexOf(this.selectedProduct);
            // Set products that are lower price to disabled
            for (let i = 0; i < this.products.length; i++) {
              if (i < selectedProductIndex) {
                this.products[i].disabled = true;
              } else {
                break;
              }
            }
          }
        }
      }
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    if (this.destroy$) {
      this.destroy$.next();
      this.destroy$.complete();
    }
  }

  updateInfoData() {
    if (!this.selectedPrice) {
      return;
    }
    this.totalPrice = this.paymentService.CalculatePricePerMonth(this.selectedProduct, this.quantity);
    this.totalPriceTtc = +(this.totalPrice * 1.081).toFixed(2);
  }

  buy() {
    if (
      !this.selectedPrice
      || !this.selectedProduct
      || !this.quantity) {
      return;
    }

    const checkoutData: CheckoutModel = {
      product: this.selectedProduct,
      price: this.selectedPrice,
      nbOfLicenses: this.quantity
    };

    sessionStorage.setItem(CheckoutDataKey, JSON.stringify(checkoutData));

    this.router.navigate(['/subscription', 'checkout']);
  }

  changeSubscription() {
    if (!this.currentSubscription ||
      (this.currentSubscription.Quantity === this.quantity
        && this.currentSubscription.RecurringPrice.Id === this.selectedPrice.Id)) {
      return;
    }

    const checkoutData: CheckoutModel = {
      product: this.selectedProduct,
      price: this.selectedPrice,
      nbOfLicenses: this.quantity,
      originalNbOfLicenses: this.currentSubscription.Quantity,
      originalPrice: this.currentSubscription.RecurringPrice,
      originalProduct: this.currentSubscription.RecurringPrice.Product,
      originalRenewingDate: this.currentSubscription.NextRenewalDate
    };

    sessionStorage.setItem(UpdateSubscriptionDataKey, JSON.stringify(checkoutData));

    this.router.navigate(['/subscription', 'update']);
  }

  selectProduct(product: StripeProduct) {
    if (this.selectedProduct === product || product.disabled) {
      return;
    }
    this.selectedProduct = product;
    if (!this.currentSubscription) {
      this.selectedPrice = product.Prices[0];
    } else {
      // Try to assign the existing price in the subcription (will be null if it not the same product)
      const subscriptionPrice = this.selectedProduct.Prices.find(p => p.Id === this.currentSubscription.RecurringPrice.Id);

      if (!subscriptionPrice) {
        // Try to find the price with the same interval
        this.selectedPrice = this.selectedProduct.Prices
          .find(p => p.Interval === this.selectedPrice.Interval
            && p.IntervalCount === this.selectedPrice.IntervalCount);

        // Otherwise, take the first price available
        if (!this.selectedPrice) {
          this.selectedPrice = this.selectedProduct.Prices[0];
        }
      } else {
        this.selectedPrice = subscriptionPrice;
      }
    }
    this.updateInfoData();
  }

  selectPrice() {
    this.updateInfoData();
  }

  quantityChanged(newQuantity: string) {
    this.quantity = Math.max(1, +newQuantity);
    this.updateInfoData();
  }

  ensureQuantityAboveZero() {
    if (this.quantity <= 0) {
      this.quantity = 1;
    }
  }
}
