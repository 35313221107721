<on-loading *ngIf="isLoading"></on-loading>


<!-- Employees Grid -->
<div class="col" [hidden]="!employeesData || isLoading">
  <br>
  <div class="col">
    <h3 class="employees-title">
      <span>{{'company.employees.title' | translate}}</span>
      <button *ngIf="isAdmin" class="btn btn-sm btn-primary float-right" (click)="addEmployee()">
        <nb-icon icon="fa-plus"></nb-icon>
        {{'company.employees.add' | translate}}
      </button>
    </h3>
    <div class="row col-12 d-flex justify-content-center align-items-center">
      <div class="col-8">
        <div class="input-group">
          <div class="input-group-text input-rounded">
            <nb-icon icon="fa-search"></nb-icon>
          </div>
          <input type="text" [(ngModel)]="searchTerms" class="form-control input-rounded"
            [placeholder]="'company.employees.search' | translate" (input)="onChangeFilter()">
        </div>
      </div>
      <div class="col-4">
        <ng-select [items]="additionalColumns" [multiple]="true" [closeOnSelect]="false" [hideSelected]="true"
          bindLabel="headerName" [placeholder]="'table.display-more-columns' | translate"
          [(ngModel)]="selectedAdditionalColumns" (add)="columnAdded($event)" (remove)="columnRemoved($event)"
          (clear)="columnCleared($event)">
        </ng-select>
      </div>
    </div>
    <br>
  </div>
  <ag-grid-angular #agGridEmployees class="ag-theme-material">
  </ag-grid-angular>
  <div class="row" *ngIf="!isLoading && employeesData">
    <div class="col text-end" *ngIf="company$ | async as company">
      <span class="licenses-status" *ngIf="!company.HasFloatingLicense">
        {{'company.employees.licences' | translate:
        {
        remains: (employeesData.TotalSubscriptionNotAssigned),
        all: employeesData.TotalSubscriptionNb
        }
        }}
      </span>
      <span class="licenses-status" *ngIf="company.HasFloatingLicense">
        {{'company.employees.floatingLicense' | translate:
        {
        nb: (employeesData.TotalSubscriptionNb)
        }
        }}
      </span>
    </div>
  </div>
</div>
<!-- -->


<!-- Invitations Grid -->
<div class="col" [hidden]="isLoading">

  <div class="col">
    <h3>{{'company.employees.invitations' | translate}}</h3>
    <br>
  </div>
  <div>
    <ag-grid-angular #agGridInvitations class="ag-theme-material">
    </ag-grid-angular>
  </div>
</div>