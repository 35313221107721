import { Component, OnInit, Input } from '@angular/core';
import { PaymentService } from 'on-payment/_services/payment.service';
import { StripeSubscription } from '../../_models/StripeSubscription';

@Component({
  selector: 'on-stripe-subscription-summary-card',
  templateUrl: './stripe-subscription-summary-card.component.html',
  styleUrls: ['./stripe-subscription-summary-card.component.scss']
})
export class StripeSubscriptionSummaryCardComponent implements OnInit {

  @Input() subscription: StripeSubscription;
  isLoading: boolean;
  totalPrice: number;

  constructor(private paymentServuce: PaymentService) {
  }

  ngOnInit(): void {
    this.totalPrice = this.paymentServuce.CalculatePricePerMonth(this.subscription.RecurringPrice.Product, this.subscription.Quantity);
  }
}
