<div class="row justify-content-md-center">
    <share-buttons
        [include]="['facebook','twitter','linkedin']"
        [show]="3"
        [showText]="true"
        [url]="'https://fastfees.ch'"
        [autoSetMeta]="false"
        theme="material-dark"
    ></share-buttons>
</div>
