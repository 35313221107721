<h1 class="row">
  <div class="col-11 p-0 pb-5">
    <a class="arrow-back" (click)="back()" *ngIf="showBackButton">
      <nb-icon icon="fa-arrow-left"></nb-icon>
    </a>
    <nb-icon [icon]="icon"></nb-icon>
    {{firstWord}} {{restOfTitle}}
  </div>
  <div class="col-1 p-0 d-flex justify-content-center" *ngIf="showReloadButton">
    <a class="reload" (click)="reloadEvent()">
      <nb-icon icon="fa-sync-alt"></nb-icon>
    </a>
  </div>
</h1>
