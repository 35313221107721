<div class="card">
  <div class="card-body">
    <h4 class="card-title">Mot de passe oublié ?</h4>
    <div *ngIf="!isLoading">
      <p>Vous pouvez le changer en passant par ici. Rentrez l'adresse mail
        utilisée pour votre compte {{appName}}</p>
      <p>
        <input class="form-control" [(ngModel)]="email" placeholder="Votre adresse email">
      </p>
      <p class="text-center">
        <button class="btn btn-primary" (click)="ForgotPassword()">Envoyer</button>
      </p>
    </div>
    <on-loading *ngIf="isLoading"></on-loading>
  </div>
</div>