import { NgModule } from '@angular/core';
import { StripePaymentComponent } from './_components/stripe-payment/stripe-payment.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxStripeModule } from 'ngx-stripe';
import { PaymentProcessComponent } from './payment-process/payment-process.component';
import { RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { NbStepperModule } from '@nebular/theme';
import { StripeSubscriptionSummaryCardComponent } from './_components/stripe-subscription-summary-card/stripe-subscription-summary-card.component';
import { UpdateSubscriptionComponent } from './update-subscription/update-subscription.component';
import { StripeVpPaymentComponent } from './stripe-vp-payment/stripe-vp-payment.component';
import { OnCommonModule } from 'on-common/on-common.module';

@NgModule({
  declarations: [
    StripePaymentComponent,
    PaymentProcessComponent,
    StripeSubscriptionSummaryCardComponent,
    UpdateSubscriptionComponent,
    StripeVpPaymentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxStripeModule.forRoot(environment.stripePKey,{
      stripeAccount: environment.stripeAccount
    }),
    OnCommonModule,
    NbStepperModule,
    SharedModule
  ],
  exports: [
    StripePaymentComponent,
    StripeSubscriptionSummaryCardComponent,
    StripeVpPaymentComponent
  ]
})
export class PaymentModuleWithoutRouting { }


@NgModule({
  declarations: [],
  imports: [
    PaymentModuleWithoutRouting,
    RouterModule.forChild([
      {
        path: 'checkout',
        component: PaymentProcessComponent
      },
      {
        path: 'update',
        component: UpdateSubscriptionComponent
      }
    ])
  ],
  exports: [PaymentModuleWithoutRouting]
})
export class PaymentModule { }
