<on-page-title [title]="'accountant.create-company' | translate" icon="fa-building"></on-page-title>

<!--<h2>Vous n'appartenez à aucune entreprise pour l'instant. Créez la votre maintenant !</h2>-->
<on-loading *ngIf="isLoading;else loaded"></on-loading>

<ng-template #loaded>
  <div *ngIf="currentUser$ | async as currentUser">
    <!--<p>
      La création d'une entreprise vous permettra de regrouper vos salariés sous un nom commun et gérer votre flux de
      validation.
    </p>
    <p>
      A la création de votre entreprise, 2 licences de démo pro vous seront attribuées afin que vous puissiez tester
      avec un collègue les fonctionnalités de la version PRO. <br>
      A noter que ces licences expireront automatiquement au bout d'un mois.
    </p>
    <div class="alert alert-warning">
      <nb-icon icon="fa-exclamation-triangle"></nb-icon>
      A l'expiration des licences de démos, vous serez dans l'incapacité d'utiliser ONexpense si vous n'avez pas
      acquis de licenses professionnelles.<br>
      Pour repasser à un mode gratuit, il vous faudra supprimer l'entreprise.
    </div>-->
    <on-two-columns-display [title]="'accountant.general-informations' | translate" *ngIf="!isLoading">
      <div content>
        <form [formGroup]="companyForm">
          <div class="form-group">
            <label for="name" class="label">{{'accountant.company-name' | translate}} *</label>
            <input type="text" id="name" class="form-control" formControlName="Name">
            <on-required-field-display [field]="companyForm.get('Name')"></on-required-field-display>
          </div>
          <div class="form-group">
            <label for="siret" class="label">{{'accountant.siret' | translate}}</label>
            <input type="text" id="siret" class="form-control" formControlName="SIRET">
          </div>
          <div class="form-group">
            <label for="" class="label">{{'accountant.country' | translate}} *</label>
            <on-country-select type="text" formControlName="Country"></on-country-select>
            <on-required-field-display [field]="companyForm.get('Country')"></on-required-field-display>
          </div>
          <div class="form-group">
            <label for="" class="label">{{'accountant.currency' | translate}} *</label>
            <on-currency-select type="text" formControlName="Currency"></on-currency-select>
            <on-required-field-display [field]="companyForm.get('Currency')"></on-required-field-display>
          </div>
        </form>
      </div>
    </on-two-columns-display>

    <on-two-columns-display [title]="'accountant.address' | translate" *ngIf="!isLoading">
      <div content>
        <form [formGroup]="addressForm">
          <div class="form-group">
            <label for="street1">{{'accountant.street1' | translate}}</label>
            <input type="text" class="form-control" id="street1" formControlName="Street1">
          </div>

          <div class="form-group">
            <label for="street2">{{'accountant.street2' | translate}}</label>
            <input type="text" class="form-control" id="street2" formControlName="Street2">
          </div>

          <div class="form-group">
            <label for="cityname">{{'accountant.city' | translate}}</label>
            <input type="text" class="form-control" id="cityname" formControlName="City">
          </div>

          <div class="form-group">
            <label for="zip">{{'accountant.zip-code' | translate}}</label>
            <input type="number" class="form-control" id="zip" formControlName="Zip">
          </div>
        </form>
      </div>
    </on-two-columns-display>

    <on-two-columns-display [title]="'company.create.admin-contact' | translate" *ngIf="!isLoading">
      <div content>
        <form [formGroup]="companyForm">
          <div class="form-group">
            <label for="adminemail" class="label">{{ 'company.create.email' | translate }} *</label>
            <input id="adminemail" type="text" class="form-control" formControlName="AdminEmail">
            <on-required-field-display [field]="companyForm.get('Currency')"></on-required-field-display>
          </div>
          <on-required-field-display [field]="companyForm.get('AdminEmail')"></on-required-field-display>
          <div class="form-group">
            <label for="adminphone" class="label">{{ 'company.create.phone' | translate }} *</label>
            <input id="adminphone" type="text" class="form-control" formControlName="AdminPhone">
            <on-required-field-display [field]="companyForm.get('Currency')"></on-required-field-display>
          </div>
        </form>
      </div>
    </on-two-columns-display>


    <div class="row" *ngIf="!isLoading">
      <div class="col text-center">
        <button class="btn btn-primary" (click)="createCompany()">
          <nb-icon icon="fa-save"></nb-icon>
          {{ 'company.create.btn' | translate }}
        </button>
      </div>
    </div>
    <!--<ng-template #alreadySubscribed>
      <div class="alert alert-warning">
        Vous avez déjà souscris à un abonnement ONexpense Premium.<br>
        Si vous désirez tester la fonctionnalité Pro, merci de vous créer un nouveau compte.
      </div>
    </ng-template>-->
  </div>
</ng-template>
