import { AccountService } from './../_services/account.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'on-common/_services/toast.service';
import { environment } from 'projects/onexpense/src/environments/environment';

@Component({
  selector: 'on-password-recover-page',
  templateUrl: './password-recover-page.component.html',
  styleUrls: ['./password-recover-page.component.scss']
})
export class PasswordRecoverPageComponent implements OnInit {

  email: string;
  isLoading: boolean;
  appName = environment.appName;

  constructor(
    private accountService: AccountService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  ForgotPassword() {
    this.isLoading = true;
    this.accountService.ForgotPassword(this.email).subscribe(response => {
      if (response.IsSuccess) {
        this.toastService.success('Si cet email existe, vous allez recevoir un email', 'Succès');
        this.router.navigate(['/login']);
      } else {
        this.toastService.error('Une erreur est intervenue lors de la verification du mail', 'Erreur');
        this.isLoading = false;
      }
    }, error => {
      this.toastService.error('Une erreur est intervenue lors de la verification du mail', 'Erreur');
      this.isLoading = false;
    });
  }

}
