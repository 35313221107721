<on-page-title [title]="'payment.update-subscription.title' | translate"></on-page-title>

<div *ngIf="checkoutData && !isLoading">
    <p>{{'payment.update-subscription.introduction' | translate}}:</p>

    <div class="row justify-content-around align-items-center">
        <div class="subscription-info col-4">
            <on-stripe-subscription-summary-card [subscription]="originalSubscription">
            </on-stripe-subscription-summary-card>
        </div>
        <div class="col-2 text-center">
            <nb-icon style="font-size: 5em;" icon="fa-arrow-right"></nb-icon>
        </div>
        <div class="subscription-info col-4">
            <on-stripe-subscription-summary-card [subscription]="destinationSubscription">
            </on-stripe-subscription-summary-card>
        </div>
    </div>
    <br>
    <br>
    <p>{{'payment.update-subscription.regulation-invoice-description' | translate}}</p>
    <p *ngIf="prorationValue">{{'payment.update-subscription.regulation-invoice-amount' | translate}}:
        {{prorationValue}} CHF {{'payment.update-subscription.taxes-excl' | translate}}
        <br>
    </p>
    <p *ngIf="prorationValue === 0">
        {{'payment.update-subscription.next-month-applied' | translate}}
        <br>
    </p>
    <p class="text-center">
        <button class="btn btn-primary"
            (click)="applySubscriptionUpdate()">{{'payment.update-subscription.confirm-modification' |
            translate}}</button>
    </p>
</div>

<on-loading *ngIf="isLoading"></on-loading>