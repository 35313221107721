import { ExternalSigninErrorPageComponent } from './authentication/external-signin-error-page/external-signin-error-page.component';
import { SharePageComponent } from './share-onexpense/share-page/share-page.component';
import { SupportPageComponent } from './support/support-page/support-page.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { ShareOnexpenseModule } from './share-onexpense/share-onexpense.module';
import { SupportModule } from './support/support.module';
import { AccountMenuComponent } from './account/_shared/account-menu/account-menu.component';
import { AccountModuleWithoutRouting } from './account/account.module';
import { CreateCompanyPageComponent } from './accountant-expert/create-company-page/create-company-page.component';
import { SelectCompanyComponent } from './company/_shared/select-company/select-company.component';
import { CurrentUserGuard } from './shared/_guards/current-user.guard';
import { NoSidebarPageComponent } from 'on-layout/no-sidebar-page/no-sidebar-page.component';
import { LoginPageComponent } from 'on-auth/login-page/login-page.component';
import { RegisterPageComponent } from 'on-auth/register-page/register-page.component';
import { ExternalSignInPageComponent } from 'on-auth/external-sign-in-page/external-sign-in-page.component';
import { PasswordRecoverPageComponent } from 'on-auth/password-recover-page/password-recover-page.component';
import { ResetPasswordPageComponent } from 'on-auth/reset-password-page/reset-password-page.component';
import { SidebarPageComponent } from 'on-layout/sidebar-page/sidebar-page.component';
import { IsAuthenticatedGuardService } from 'on-auth/_services/is-authenticated-guard.service';
import { OnAuthModule } from 'on-auth/on-auth.module';
import { ConnectedAsStatusComponent } from './accountant-expert/_shared/connected-as-status/connected-as-status.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'expenses',
    pathMatch: 'full',
  },
  {
    path: 'doc',
    loadChildren: () =>
      import('./documentation/documentation.module').then(
        (mod) => mod.DocumentationModule,
      ),
  },
  {
    path: '', // redirige vers les pages sans topbar
    component: NoSidebarPageComponent,
    children: [
      {
        path: 'login',
        component: LoginPageComponent,
        data: { msExternalLogin: environment.windowsLogin },
      },
      { path: 'register', component: RegisterPageComponent },
      { path: 'externalsignin', component: ExternalSignInPageComponent },
      { path: 'externalsigninerror', component: ExternalSigninErrorPageComponent },
      { path: 'lostpassword', component: PasswordRecoverPageComponent },
      { path: 'resetpassword', component: ResetPasswordPageComponent },
      {
        path: 'invitation',
        loadChildren: () =>
          import('./invitation/invitation.module').then(
            (m) => m.InvitationModule,
          ),
      },
    ],
  },
  {
    path: '', // redirige vers les pages avec topbar
    component: SidebarPageComponent,
    canActivate: [CurrentUserGuard],
    data: {
      logo: './assets/logo-200.png',
      leftComponentToDisplay: SelectCompanyComponent,
      middleComponentToDisplay: ConnectedAsStatusComponent,
      rightComponentToDisplay: AccountMenuComponent,
      languagesAvailable: [
        {
          Name: 'Français',
          Code: 'fr',
        },
        {
          Name: 'English',
          Code: 'en',
        },
        {
          Name: 'Deutsch',
          Code: 'de',
        },
        {
          Name: 'Español',
          Code: 'es',
        },
      ],
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },

      {
        path: 'expenses',
        loadChildren: () =>
          import('./expense/expense.module').then((m) => m.ExpenseModule),
      },

      {
        path: 'reports',
        loadChildren: () =>
          import('./report/report.module').then((m) => m.ReportModule),
      },

      {
        path: 'account',
        loadChildren: () =>
          import('./account/account.module').then((m) => m.AccountModule),
      },
      {
        path: 'company',
        loadChildren: () =>
          import('./company/company.module').then((m) => m.CompanyModule),
      },
      {
        path: 'subscription',
        loadChildren: () =>
          import('./payment/payment.module').then((m) => m.PaymentModule),
      },
      // Accountant expert
      {
        path: 'accountant_expert',
        loadChildren: () =>
          import('./accountant-expert/accountant-expert.module').then(
            (m) => m.AccountantExpertModule,
          ),
      },
      { path: 'company/new', component: CreateCompanyPageComponent },

      { path: 'support', component: SupportPageComponent },
      { path: 'share', component: SharePageComponent },
    ],
    canActivateChild: [IsAuthenticatedGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
    OnAuthModule,
    SupportModule,
    ShareOnexpenseModule,
    AccountModuleWithoutRouting,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
