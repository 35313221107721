<on-loading *ngIf="isLoading"></on-loading>

<div *ngIf="!isLoading">
  <div class="row">
    <div class="col-12" *ngIf="!currentSubscription">
      <i>{{'payment.stripe-payment.choose-license-type' | translate}}</i>
    </div>
    <div class="d-flex col-12 justify-content-around">
      <div class="product-item rounded" [ngClass]="{
        'selected': basicProduct === selectedProduct,
        'shadow': basicProduct === selectedProduct,
        'shadow-sm': basicProduct !== selectedProduct,
        'disabled': basicProduct.disabled
      }" (click)="selectProduct(basicProduct)" *ngIf="basicProduct && !basicProduct.disabled">
        <h4 class="option-title text-center">{{basicProduct.Name}}</h4>
        <div *ngIf="!currentSubscription">
          <div *ngIf="!currentSubscription">
            <p class="text-center">Prix dégressif :</p>
            <ul>
              <li>4,90CHF HT par utilisateur par mois pour les 10 premières licences</li>
              <li>4,50CHF HT par utilisateur par mois pour les suivantes</li>
            </ul>
          </div>
        </div>
        <div *ngIf="selectedProduct === basicProduct">
          <div *ngIf="currentSubscription">
            <p>{{selectedPrice.Price | number:'.2-2'}}{{'payment.stripe-payment.per-user-every' | translate}}
              {{selectedPrice.IntervalCount}}
              {{selectedPrice.Interval | translate}}</p>
          </div>
          <div *ngIf="selectedPrice">
            <br>
            <div>
              <label class="m-0">{{'payment.stripe-payment.nb-of-licenses' | translate}}:</label>
              <input (blur)="ensureQuantityAboveZero()" class="form-control" type="number" [min]="1" [ngModel]="quantity" (ngModelChange)="quantityChanged($event)">
            </div>
          </div>
        </div>
      </div>
      <div class="product-item rounded" [ngClass]="{
                'selected': premiumProduct === selectedProduct,
                'shadow': premiumProduct === selectedProduct,
                'shadow-sm': premiumProduct !== selectedProduct,
                'disabled': premiumProduct.disabled
              }" (click)="selectProduct(premiumProduct)" *ngIf="premiumProduct && !premiumProduct.disabled">
        <h4 class="option-title text-center">{{premiumProduct.Name}}</h4>
        <div *ngIf="!currentSubscription">
          <p class="text-center">Prix dégressif :</p>
          <ul>
            <li>6,90CHF HT par utilisateur par mois pour les 10 premières licences</li>
            <li>4,50CHF HT par utilisateur par mois pour les suivantes</li>
          </ul>
        </div>
        <div *ngIf="selectedProduct === premiumProduct">
          <div *ngIf="currentSubscription">
            <p>{{selectedPrice.Price | number : '.2-2'}}{{'payment.stripe-payment.per-user-every' | translate}}
              {{selectedPrice.IntervalCount}}
              {{selectedPrice.Interval | translate}}</p>
          </div>
          <div *ngIf="selectedPrice">
            <br>
            <div>
              <label class="m-0">{{'payment.stripe-payment.nb-of-licenses' | translate}}:</label>
              <input (blur)="ensureQuantityAboveZero()" class="form-control"  type="number" [min]="1" [ngModel]="quantity" (ngModelChange)="quantityChanged($event)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 align-items-center m-0" *ngIf="selectedPrice && quantity && !currentSubscription">
      <br>
      <p *ngIf="!selectedPrice.IsFloating" class="font-italic"
        [innerHTML]="'payment.stripe-payment.buy-summary' | translate : {quantity: quantity, selectedProductName: selectedProduct.Name, totalPriceHt: (totalPrice | number : '.2'), totalPriceTtc: (totalPriceTtc | number : '.2')}">
      </p>
      <p *ngIf="selectedPrice.IsFloating">
        {{selectedPrice.Description}}
      </p>
    </div>
  </div>

  <div class="row" *ngIf="selectedPrice && quantity">
    <div class="col-12 text-center mt2" *ngIf="!currentSubscription">
      <button class="btn btn-primary" (click)="buy()">
        {{'payment.stripe-payment.buy-confirmation' | translate:{totalPrice: (totalPrice | number : '.2')} }}
      </button>
    </div>

    <div class="col-12 text-center mt2"
      *ngIf="currentSubscription && (selectedPrice.Id !== currentSubscription.RecurringPrice.Id || quantity !== currentSubscription.Quantity)">
      <br>
      <div class="col-12 text-center mt2">
        <button class="btn btn-primary" (click)="changeSubscription()">{{'payment.stripe-payment.edit-subscription' |
          translate}}</button>
      </div>
    </div>
  </div>
</div>