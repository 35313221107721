
<!-- <div class="modal-header">
  <h2 class="modal-title" id="modal-basic-title">Vous aimez ONexpense ?</h2>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h4>Faites le savoir !</h4>
  <p>En partageant sur les réseaux sociaux, vous nous aiderez grandement à faire connaitre ONexpense et ainsi accélérer
    les améliorations futures pour vous rendre la vie toujours plus simple !</p>
  <on-sharebuttons></on-sharebuttons>
</div>
<div class="modal-footer">
</div>-->

<on-page-title title="Vous aimez {{appName}} ?" icon="share"></on-page-title>

<div>
  <h4>{{'share.title' | translate}}</h4>
  <p>{{'share.text' | translate : {appName:appName} }}</p>
  <on-sharebuttons></on-sharebuttons>
</div>
