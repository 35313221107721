import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountantService } from 'onexpense/company/accountant/_services/accountant.service';
import { AccountingCode } from 'onexpense/company/accountant/_models/AccountingCode';
import { OnUser } from 'projects/onexpense/src/app/shared/_models/OnUser';
import { VatChargeAccount } from 'onexpense/company/accountant/_models/VatChargeAccount';
import { Select, Store } from '@ngxs/store';
import { AccountantExportState } from 'projects/onexpense/src/app/shared/stores/accountant-export/accountant-export.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountantExportAction } from 'projects/onexpense/src/app/shared/stores/accountant-export/accountant-export.actions';
import { UserState } from 'projects/onexpense/src/app/shared/stores/user/user.state';
import { ToastService } from 'projects/onexpense/src/app/common/_services/toast.service';

@Component({
  selector: 'on-accountant-administration',
  templateUrl: './accountant-administration.component.html',
  styleUrls: ['./accountant-administration.component.scss'],
})
export class AccountantAdministrationComponent implements OnInit, OnDestroy {

  isLoadingAccountingCodes = true;
  isLoadingVatCharges = true;
  connectedUser: OnUser;
  accountingCodes: Array<AccountingCode>;
  vatChargeAccounts: VatChargeAccount[];

  // Compte de contrepartie
  ttcChargeAccount: VatChargeAccount;

  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;
  @Select(AccountantExportState.accountingCodes) accountingCodes$: Observable<
    AccountingCode[]
  >;

  private destroy$ = new Subject<void>();

  constructor(
    private accountantService: AccountantService,
    private toastService: ToastService,
    private store: Store,
  ) { }

  ngOnInit() {
    this.isLoadingAccountingCodes = true;
    this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.connectedUser = user;
      this.store.dispatch(
        new AccountantExportAction.LoadAccountingCodesIfNeeded(
          this.connectedUser.CompanyId,
        ),
      );
    });

    this.loadAccountingCodes();
    this.loadChargeAccounts();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadAccountingCodes() {
    this.accountingCodes$
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response) {
          this.accountingCodes = response.map((c) => new AccountingCode(c));
          this.isLoadingAccountingCodes = false;
        }
      });
  }

  loadChargeAccounts() {
    this.isLoadingVatCharges = true;
    this.accountantService.GetVatChargeAccounts(this.connectedUser.CompanyId).subscribe(response => {
      if (response.IsSuccess) {
        this.vatChargeAccounts = response.Result.filter(ca => ca.Vat !== 100);
        this.ttcChargeAccount = response.Result.find(ca => ca.Vat === 100);
        if (!this.ttcChargeAccount) {
          this.ttcChargeAccount = {
            Code: '',
            Vat: 100
          };
        }
        this.isLoadingVatCharges = false;
      } else {
        this.toastService.error('Echec lors du chargement', 'Erreur');
      }
    });
  }

  saveAccountingCodes() {
    this.store.dispatch(
      new AccountantExportAction.SaveAccountingCodes(
        this.connectedUser.CompanyId,
        this.accountingCodes,
      ),
    );

  }

  reload() {
    this.isLoadingAccountingCodes = true;
    this.store.dispatch(
      new AccountantExportAction.LoadAccountingCodes(
        this.connectedUser.CompanyId,
      ),
    );
  }

  saveVatChargeAccount() {
    this.isLoadingVatCharges = false;
    this.accountantService.SaveVatChargeAccounts(this.connectedUser.CompanyId, [...this.vatChargeAccounts, this.ttcChargeAccount])
      .subscribe(response => {
        if (response.IsSuccess) {
          this.toastService.success('Sauvegarde réussie');
        } else {
          this.toastService.error(response.Message, 'Echec');
        }
        this.isLoadingVatCharges = false;
      });
  }

  addVatCharge() {
    this.vatChargeAccounts.push({
      Vat: 10,
      Code: ''
    });
  }

  deleteVatCharge(charge: VatChargeAccount) {
    const idx = this.vatChargeAccounts.indexOf(charge);
    this.vatChargeAccounts.splice(idx, 1);
  }
}
