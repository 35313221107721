<on-page-title [title]="'payment.payment-process.title' | translate : {appName:appName}"></on-page-title>

<div class="process-container">
    <nb-stepper orientation="horizontal" [linear]="true" [disableStepNavigation]="true">

        <!-- your steps content here -->
        <nb-step id="invoice-step" [label]="'payment.payment-process.billing-information' | translate">
            <p class="text-center">{{'payment.payment-process.billing-information-description' | translate}}</p>
            <div class="card shadow">
                <form novalidate (submit)="goToPayment()" [formGroup]="customerFormGroup" [hidden]="isLoading"
                    class="row justify-content-around">
                    <div class="form-group col-12">
                        <label for="companyName" class="label">{{'payment.payment-process.billing-company-name' |
                            translate}}</label>
                        <input id="companyName" class="form-control" formControlName="CompanyName">
                    </div>
                    <div class="form-group col-6">
                        <label for="fullName" class="label">{{'payment.payment-process.billing-name' |
                            translate}}</label>
                        <input id="fullName" class="form-control" formControlName="FullName">
                    </div>
                    <div class="form-group col-6">
                        <label for="email" class="label">{{'payment.payment-process.billing-email' | translate}}</label>
                        <input id="email" class="form-control" formControlName="Email">
                    </div>
                    <div class="form-group col-12">
                        <label for="address" class="label">{{'payment.payment-process.billing-address' |
                            translate}}</label>
                        <input id="address" class="form-control" formControlName="Address">
                    </div>
                    <div class="form-group col-8">
                        <label for="city" class="label">{{'payment.payment-process.billing-city' | translate}}</label>
                        <input id="city" class="form-control" formControlName="City">
                    </div>
                    <div class="form-group col-4">
                        <label for="zip" class="label">{{'payment.payment-process.billing-zip' | translate}}</label>
                        <input id="zip" class="form-control" formControlName="ZipCode">
                    </div>
                    <button class="btn btn-primary mt-3" type="submit">
                        {{'payment.payment-process.billing-validate' | translate}}
                    </button>
                </form>
                <on-loading *ngIf="isLoading"></on-loading>
            </div>
        </nb-step>
        <nb-step id="payment-step" [label]="'payment.payment-process.payment' | translate">
            <div class="card shadow">
                <div [hidden]="isLoading">
                    <h2>{{'payment.payment-process.subscription-summary' | translate}}</h2>
                    <p *ngIf="checkoutData && !checkoutData.price.IsFloating">
                        {{'payment.payment-process.type-license' | translate}}:
                        {{checkoutData.product.Name}}<br>
                        {{'payment.payment-process.nb-of-license' | translate}}: {{checkoutData.nbOfLicenses}}<br><br>
                        {{'payment.payment-process.first-invoice-price' | translate}}:
                        CHF {{ totalPrice | number:'.2-2'}} {{'payment.payment-process.ht' | translate}}<br>
                        {{'payment.payment-process.auto-renew' | translate}}:
                    </p>
                    <p *ngIf="checkoutData && checkoutData.price.IsFloating">
                        {{'payment.payment-process.type-license' | translate}}:
                        {{checkoutData.product.Name}}<br>
                        {{'payment.payment-process.type-renew' | translate}}:
                        {{checkoutData.price.Description}}<br>
                    </p>

                    <h2>{{'payment.payment-process.banking-info' | translate}}</h2>
                    <p class="text-end">
                        <a href="http://www.stripe.com/" target="_blank"><img alt="Powered by Stripe"
                                src="/assets/images/powered_by_stripe.png"></a>
                    </p>
                    <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                        <label class="btn btn-secondary" [class.active]="selectedPaymentMethod == 'card'">
                            <input type="radio" value="card" name="paymentMethod" id="card" autocomplete="off"
                                [(ngModel)]="selectedPaymentMethod">
                            Carte
                        </label>
                        <label class="btn btn-secondary" [class.active]="selectedPaymentMethod == 'sepa'">
                            <input type="radio" value="sepa" name="paymentMethod" id="sepa" autocomplete="off"
                                [(ngModel)]="selectedPaymentMethod"> SEPA
                        </label>
                    </div> -->
                    <form novalidate (ngSubmit)="buy(card, iban)">
                        <div class="border rounded-right p-2" *ngIf="selectedPaymentMethod === 'card'">
                            <div class="form-group">
                                <label for="cb">{{'payment.payment-process.cc-number' | translate}}:
                                </label>
                                <ngx-stripe-card #card id="cb" [options]="cardOptions"
                                    [elementsOptions]="elementsOptions">
                                </ngx-stripe-card>
                            </div>
                        </div>
                        <!-- <div class="border rounded-right p-2" *ngIf="selectedPaymentMethod === 'sepa'">
                            <div class="form-group">
                                <label for="iban">{{'payment.payment-process.iban-number' | translate}}:
                                </label>
                                <ngx-stripe-iban #iban id="iban" [options]="ibanOptions"
                                    [elementsOptions]="elementsOptions" (change)="ibanChanged($event)">
                                </ngx-stripe-iban>
                                <hr>
                                <span>{{'payment.payment-process.iban-disclaimer' | translate}}</span>
                            </div>
                        </div> -->
                        <div class="row justify-content-end">
                            <div class="text-justify col-12 stripe-disclaimer">
                                <p> {{'payment.payment-process.stripe-disclaimer' | translate : {appName:appName} }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <input [(ngModel)]="conditionsChecked" [ngModelOptions]="{standalone: true}"
                                    class="checkbox" id="conditions" type="checkbox">&nbsp;
                                <label style="display: inline;"
                                    for="conditions">{{'payment.payment-process.confirm-conditions' |
                                    translate}}</label>
                            </div>
                        </div>
                        <div class="row" *ngIf="showConfirmationRequired && !conditionsChecked">
                            <div class="col-12">
                                <p class="font-italic text-warning">{{'payment.payment-process.confirmation-required' |
                                    translate}}
                                </p>
                            </div>
                        </div>
                        <br>
                        <div class="row justify-content-around">
                            <div class="col-6 text-center">
                                <button class="btn btn-secondary" type="button"
                                    (click)="previousStep()">{{'payment.payment-process.previous' | translate}}</button>
                            </div>
                            <div class="col-6 mt-3 text-center">
                                <button class="btn btn-primary" type="submit">
                                    {{'payment.payment-process.subscribe' | translate}}
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
                <on-loading *ngIf="isLoading"></on-loading>
            </div>
        </nb-step>

        <nb-step id="success-step" [label]="'payment.payment-process.activation' | translate">
            <div class="card shadow">
                <nb-icon icon="fa-check" status="success"></nb-icon>
                <p [innerHTML]="'payment.payment-process.activation-success' | translate">
                </p>
            </div>
        </nb-step>
    </nb-stepper>
</div>