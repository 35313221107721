<div class="row" *ngIf="(expense.ExpenseType.Code === 'MGE' && !connectedOnUser.CompanyId); else canExpense">
  <div class="col">
    <div class="alert alert-warning">
      <nb-icon icon="fa-exclamation-triangle" class="mr-2"></nb-icon>
      {{ 'expense.mileage.no-company-info' | translate }}
      <a [routerLink]="['/company']">{{ 'expense.mileage.no-company-info-here' | translate }}</a>
    </div>
  </div>
</div>

<ng-template #canExpense>
  <form action="" [formGroup]="receiptForm" *ngIf="expense">
    <div class="row" *ngIf="!expense.IsEditable">
      <div class="col">
        <div class="alert alert-warning">
          <nb-icon icon="fa-exclamation-triangle"></nb-icon>
          {{'expense.in-report' | translate}}
        </div>
      </div>
    </div>

    <!-- TOTAL & CURRENCY -->
    <div class="row" *ngIf="expense.ExpenseType.Code !== 'MGE'">
      <div class="col form-group">
        <label for="">{{'expense.total' | translate}}</label>
        <div class="input-group" [class.incoherent]="incoherencyDetected">
          <span class="input-group-text">
            <!-- <nb-icon icon="fa-euro-sign"></nb-icon> -->
            <span *ngIf="expense.Currency.Symbol">{{expense.Currency.Symbol}}</span>
            <span *ngIf="!expense.Currency.Symbol">
              <nb-icon class="fa-money-bill-alt"></nb-icon>
            </span>
          </span>
          <input libEnforcePointSeparator [disabled]="!expense.IsEditable" name="total" class="form-control"
            [(ngModel)]="expense.GlobalAmount.TtcOrigin" [ngModelOptions]="{ standalone: true }"
            (change)="TotalTtcChanged()" />
          <div class="input-group-text" *ngIf="incoherencyDetected">
            <nb-icon [nbPopover]="'expense.incoherency' | translate" nbPopoverTrigger="hint" nbPopoverPlacement="bottom"
              icon="fa-exclamation-triangle" class="text-warning"></nb-icon>
          </div>
        </div>
      </div>
      <div class="col form-group">
        <label for="currency">{{'expense.currency' | translate}}</label>
        <on-currency-select [disabled]="!expense.IsEditable" name="currency" [(ngModel)]="expense.Currency"
          [ngModelOptions]="{ standalone: true }" (currencyChange)="currencyChanged(true)"></on-currency-select>
      </div>
      <div class="col form-group" *ngIf="isDifferentCurrency">
        <label for="ratio">{{'expense.ratio' | translate}}</label>
        <div class="input-group">
          <input libEnforcePointSeparator id="ratio" class="form-control" [disabled]="!expense.IsEditable"
            name="conversionratio" [(ngModel)]="expense.ConversionRatio" [ngModelOptions]="{ standalone: true }" (change)="currencyRatioChanged()" />
          <button type="button" class="btn btn-white" (click)="getCurrencyRate(true)"
            [ngbTooltip]="'expense.retrieve-last-ratio' | translate">
            <nb-icon icon="fa-sync"></nb-icon>
          </button>
        </div>
        <div class="d-flex justify-content-end">
          <label>
            <i *ngIf="conversionRateDate"><small>{{'expense.ratio-date' | translate}}
                {{ conversionRateDate | date : ('date.format-number' | translate) }} </small></i>
            <ng-template #tipContent><i>{{'expense.ratio-date-mismatch' | translate}}</i></ng-template>
            <nb-icon icon="fa-exclamation-circle" *ngIf="conversionRateWarning" [ngbTooltip]="tipContent"
              placement="bottom"></nb-icon>
          </label>
        </div>
      </div>
    </div>
    <!-- KILOMETERS + RATE-->
    <div class="row" *ngIf="expense.ExpenseType.Code === 'MGE'">
      <div class="col-12 alert alert-warning" *ngIf="needVehicule && vehicles && !vehicles.length">
        {{'expense.no-vehicle-configured' | translate}}
      </div>
      <div class="col-4 form-group" *ngIf="needVehicule">
        <label>{{'expense.vehicle' | translate}}</label>
        <ng-select libEnforcePointSeparator [disabled]="!expense.IsEditable" [items]="vehicles" bindLabel="Name"
          bindValue="Id" [(ngModel)]="selectedVehicleId" [ngModelOptions]="{ standalone: true }" [clearable]="false"
          [searchable]="false" (change)="calculateMileageTotal()"></ng-select>
      </div>

      <div class="col-4 form-group">
        <div class="ml-4">
          <label for="distance">
            <input class="form-check-input" type="radio" name="choice" [value]="true" [(ngModel)]="kilometersFree"
              [ngModelOptions]="{ standalone: true }" id="distance">
            {{'expense.distance' | translate}}
          </label>
        </div>
        <div class="input-group">
          <button class="btn btn-outline-dark" (click)="openMapModal()">
            <span class="d-flex">
              <nb-icon icon="fa-globe-europe"></nb-icon>
            </span>
          </button>
          <input libEnforcePointSeparator type="number" [disabled]="!expense.IsEditable || !kilometersFree"
            (keyup)="calculateMileageTotal()" (change)="calculateMileageTotal()" [(ngModel)]="expense.Distance"
            [ngModelOptions]="{ standalone: true }" id="kilometers" class="form-control" />
          <span class="input-group-text">
            {{'expense.km' | translate}}
          </span>
        </div>
      </div>

      <div class="col-4 form-group">
        <label class="ml-4" for="nbJourneyHomeToWork">
          <input class="form-check-input" type="radio" name="choice" [value]="false" [(ngModel)]="kilometersFree"
            [ngModelOptions]="{ standalone: true }" id="nbJourneyHomeToWork">
          {{'expense.nb-journey-home-to-work' | translate}}
        </label>
        <div class="input-group">
          <input libEnforcePointSeparator type="number" [disabled]="!expense.IsEditable || kilometersFree"
            (keyup)="calculateDistanceFromJourney()" (change)="calculateDistanceFromJourney()"
            [(ngModel)]="expense.NbJourneyHomeToWork" [ngModelOptions]="{ standalone: true }" min="1" step="1"
            class="form-control" />
        </div>
      </div>

      <div class="col-4 form-group">
        <label for="startplace">{{'expense.km-start' | translate}}</label>
        <input type="text" [disabled]="!expense.IsEditable" (change)="calculateMileageTotal()"
          [(ngModel)]="expense.StartPlace" [ngModelOptions]="{ standalone: true }" id="startplace"
          class="form-control" />
      </div>
      <div class="col-4 form-group">
        <label for="endplace">{{'expense.km-end' | translate}}</label>
        <input type="text" [disabled]="!expense.IsEditable" (change)="calculateMileageTotal()"
          [(ngModel)]="expense.EndPlace" [ngModelOptions]="{ standalone: true }" id="endplace" class="form-control" />
      </div>

      <div class="col-4 form-group" *ngIf="needCarRate">
        <label for="rate">{{'expense.rate' | translate}}</label>
        <input libEnforcePointSeparator (change)="recalculateKmTotal()" [(ngModel)]="carRate"
          [ngModelOptions]="{ standalone: true }" id="rate" class="form-control" />
      </div>

      <div class="col-4 form-group">
        <label>{{'expense.total' | translate}}</label>
        <div class="input-group">
          <input libEnforcePointSeparator [disabled]="true" name="total" class="form-control"
            (change)="recalculateKmTotal()" [(ngModel)]="expense.GlobalAmount.TtcOrigin"
            [ngModelOptions]="{ standalone: true }" />
          <span class="input-group-text" *ngIf="referenceCurrency">
            {{ referenceCurrency.Symbol }}
          </span>
          <div class="d-flex align-items-center ml-1 col-1 p-0">
            <div class="spinner-border spinner-border-sm spinner-color-primary" role="status" *ngIf="isLoadingTotal">
              <span class="sr-only"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <nb-accordion class="mb-1" multi>
      <nb-accordion-item #informations expanded [expanded]="true">
        <nb-accordion-item-header>{{'expense.global-info' | translate}}</nb-accordion-item-header>
        <nb-accordion-item-body>
          <!-- TO BE REIMBURSED CHECK BOX & PER DIEM-->
          <div class="row justify-content-around" *ngIf="expense.ExpenseType.Code !== 'MGE'">
            <div class="col-auto" *ngIf="connectedOnUser.HasPerDiem">
              <div class="form-check per-diem-check">
                <input [disabled]="!expense.IsEditable" type="checkbox" (change)="perDiemChanged()"
                  [(ngModel)]="expense.IsPerDiem" [ngModelOptions]="{ standalone: true }" class="form-check-input"
                  id="per-diem">
                <label class="form-check-label" for="per-diem">{{'expense.per-diem-expense' |
                  translate}}</label>
                &nbsp;
                <nb-icon icon="fa-question-circle" [nbTooltip]="'expense.per-diem-expense-description' | translate"
                  nbPopoverTrigger="hint" nbTooltipPlacement="bottom"></nb-icon>
              </div>
            </div>
            <div class="col-auto" *ngIf="!expense.IsPerDiem">
              <div class="form-check to-reimburse-check">
                <input [disabled]="!expense.IsEditable" type="checkbox" (change)="toReimburseChanged()"
                  [(ngModel)]="expense.PaidWithPersonalAccount" [ngModelOptions]="{ standalone: true }"
                  class="form-check-input" id="personalExpense">
                <label class="form-check-label" for="personalExpense">{{'expense.was-personal-expense' |
                  translate}}</label>
                &nbsp;
                <nb-icon icon="fa-question-circle" [nbTooltip]="'expense.was-personal-expense-description' | translate"
                  nbPopoverTrigger="hint" nbTooltipPlacement="bottom"></nb-icon>
              </div>
            </div>
          </div>
          <hr>
          <!-- COUNTRY & DATE -->
          <div class="row">
            <div class="col form-group">
              <label for="country">{{'expense.country' | translate}}</label>
              <on-country-select [disabled]="!expense.IsEditable" (change)="changeCountry()"
                [(ngModel)]="expense.Country" [ngModelOptions]="{ standalone: true }"></on-country-select>
            </div>
            <div class="col form-group">
              <label for="date">{{'expense.date' | translate}}
                <span id="dateErrorMessage" class="ml-4" style="color: red; font-size: 0.8rem; visibility: hidden;"
                  *ngIf="true">La date du trajet doit être renseignée</span>
              </label>

              <div class="input-group">
                <a class="btn btn-outline-secondary" (click)="expense.IsEditable && d.toggle()">
                  <nb-icon icon="fa-calendar"></nb-icon>
                </a>
                <input id="inputDate" [disabled]="!expense.IsEditable" class="form-control" placeholder="jj/mm/aaaa"
                  name="dp" [ngModelOptions]="{ standalone: true }" [(ngModel)]="expense.DateOnExpense" ngbDatepicker
                  #d="ngbDatepicker" (ngModelChange)="updateWarning(); calculateMileageTotal()" container="body" />
              </div>
            </div>
          </div>

          <div class="row" *ngIf="expense.ExpenseType.Code !== 'MGE' && isDifferentCurrency">
            <div class="col form-group">
              <label>{{'expense.amount-to-reimbourse-in' | translate:{ symbol: referenceCurrency.Symbol } }}</label>
              <p>
                {{
                'expense.amount-to-reimbourse-value'
                | translate: {
                amount: (
                expense.GlobalAmount.TtcOrigin * +expense.ConversionRatio | number: '1.2-2'
                ),
                symbol: referenceCurrency.Symbol
                }
                }}

              </p>
            </div>
          </div>

          <!-- <EXPENSE TYPE & EMMITER>-->
          <div class="row">
            <div class="col form-group" *ngIf="expense.ExpenseType.Code !== 'MGE'">
              <label>{{'expense.expense-type' | translate}}</label>
              <on-expense-type-select [isMultiple]="false" [disabled]="!expense.IsEditable"
                (ngModelChange)="expense.ExpenseType = $event;UpdateCustomFields()" [ngModel]="expense.ExpenseType"
                [ngModelOptions]="{ standalone: true }">
              </on-expense-type-select>
            </div>

            <div class="col form-group" *ngIf="expense.ExpenseType.Code !== 'MGE'">
              <label>{{'expense.transmitter' | translate}}</label>
              <input [disabled]="!expense.IsEditable" type="text" class="form-control" [(ngModel)]="expense.Emitter"
                [ngModelOptions]="{ standalone: true }" />
            </div>
          </div>
          <!-- </RECEIPT TYPE & EMMITER>-->

          <!-- INVITED USERS -->
          <div class="row align-items-center"
            *ngIf="expense.ExpenseType.Code === 'RESTINVIT' && connectedOnUser?.CompanyId">
            <div class="col form-group">
              <label for="external-invitees">{{'expense.invited-users' | translate}}</label>
              <on-select-employees id="external-invitees" [disabled]="!expense.IsEditable"
                [(ngModel)]="expense.InvitedUsers" [ngModelOptions]="{ standalone: true }"></on-select-employees>
            </div>
          </div>

          <!-- NUMBER OF NIGHTS-->
          <div class="row align-items-center"
            *ngIf="expense.ExpenseType.Code === 'HTL' || expense.ExpenseType.Code === 'HTLMULTI'">
            <div class="col form-group m-0">
              <label for="number-nights">{{'expense.number-night' | translate}}</label>
              <input class="form-control" type="number" id="number-nights" [disabled]="!expense.IsEditable"
                [(ngModel)]="expense.NumberOfNight" [ngModelOptions]="{ standalone: true }"/>
            </div>
          </div>

          <!-- Vehicule for GAS -->
          <div class="row align-items-center" *ngIf="expense.ExpenseType.Code === 'GAS'">
            <div class="col form-group">
              <label for="userComment">{{'expense.vehicle' | translate}}</label>
              <ng-select appendTo="body" libEnforcePointSeparator [disabled]="!expense.IsEditable" [items]="vehicles"
                bindLabel="Name" bindValue="Id" [(ngModel)]="expense.VehicleId" [ngModelOptions]="{ standalone: true }"
                [clearable]="false" [searchable]="false"></ng-select>
            </div>
          </div>

          <!-- <COMMENTS> -->
          <div class="row align-items-center">
            <div class="col form-group">
              <label for="userComment">{{'expense.comments' | translate}}</label>
              <textarea id="userComment" [class.ng-touched]="isValidated && isKilometer && !expense.Comments"
                [class.ng-invalid]="isValidated && isKilometer && !expense.Comments" [disabled]="!expense.IsEditable"
                class="form-control" cols="50" rows="1" [(ngModel)]="expense.Comments"
                [ngModelOptions]="{ standalone: true }"></textarea>
              <p class="text-danger" *ngIf="isValidated && isKilometer && !expense.Comments">
                {{'expense.comments-mandatory' | translate}}</p>
            </div>
          </div>
          <!-- </COMMENTS> -->
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item #taxes *ngIf="expense.ExpenseType.Code !== 'MGE'" [expanded]="true">
        <nb-accordion-item-header>{{'expense.taxes' | translate}} &nbsp;&nbsp;<nb-icon
            [nbPopover]="'expense.incoherency' | translate" nbPopoverTrigger="hint" nbPopoverPlacement="bottom"
            *ngIf="incoherencyDetected" icon="fa-exclamation-triangle" class="text-warning"></nb-icon>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <!-- <TABLE TAXES> -->
          <div class="row">
            <div class="col-12 taxes-section">
              <p class="text-center">
                <input id="autocalculation" type="checkbox" class="checkbox" [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="isAutoCalculationActivated">
                <label for="autocalculation">&nbsp;&nbsp;{{'expense.activate-calculations' | translate }}</label>
              </p>
              <table class="table" [class.incoherent-taxes]="incoherencyDetected">
                <!--Table head-->
                <thead class="blue-grey lighten-4">
                  <tr>
                    <th style="min-width: 100px;">{{'expense.tva%' | translate}}</th>
                    <th>{{'expense.ht' | translate: { symbol: expense.Currency.Symbol } }}</th>
                    <th>{{'expense.taxes-table' | translate: { symbol: expense.Currency.Symbol } }}</th>
                    <th>{{'expense.ttc' | translate: { symbol: expense.Currency.Symbol } }}</th>
                    <th></th>
                  </tr>
                  <!-- <tr>
                    <td class="text-center" colspan="6">Changer le pays pour appliquer les bonnes taxes</td>
                  </tr> -->
                </thead>
                <!--Table head-->

                <!--Table body-->
                <tbody>
                  <tr *ngFor="let rate of expense.TVAAmounts; let i = index"
                    [class.incoherent]="!rate.IsCoherent && nbOfIncoherentLines === 1">
                    <td>
                      <ng-select [clearable]="false" #selectedTva [disabled]="!expense.IsEditable"
                        [(ngModel)]="rate.TVA" [ngModelOptions]="{ standalone: true }" (change)="UpdateTaxesWithTVA(i)"
                        [items]="tvas" bindValue="Value" bindLabel="Readable" (search)="searchTVA($event, i)"
                        appendTo="body">
                        <ng-template ng-label-tmp ng-option-tmp let-item="item">
                          {{item.Readable}}
                        </ng-template>
                      </ng-select>
                    </td>
                    <td>
                      <div class="input-group">
                        <input libEnforcePointSeparator [disabled]="!expense.IsEditable" [(ngModel)]="rate.HtOrigin"
                          [class.user-entered]="rate.IsEnteredByUser" [ngModelOptions]="{ standalone: true }" value="HT"
                          class="form-control" (change)="UpdateTaxesWithHT(i)" />
                        <span class="input-group-text" *ngIf="!rate.IsCoherent && nbOfIncoherentLines === 1">
                          <nb-icon [nbPopover]="'expense.tva-incoherency' | translate" nbPopoverTrigger="hint"
                            nbPopoverPlacement="bottom" icon="fa-exclamation-triangle"
                            class="text-warning small-icon"></nb-icon>
                        </span>
                      </div>
                    </td>

                    <td>
                      <div class="input-group">
                        <input libEnforcePointSeparator [disabled]="!expense.IsEditable" [(ngModel)]="rate.TaxesOrigin"
                          [class.user-entered]="rate.IsEnteredByUser" [ngModelOptions]="{ standalone: true }"
                          value="Taxes" class="form-control" (change)="UpdateTaxesWithTaxes(i)" />
                        <span class="input-group-text" *ngIf="!rate.IsCoherent && nbOfIncoherentLines === 1">
                          <nb-icon [nbPopover]="'expense.tva-incoherency' | translate" nbPopoverTrigger="hint"
                            nbPopoverPlacement="bottom" icon="fa-exclamation-triangle"
                            class="text-warning small-icon"></nb-icon>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="input-group">
                        <input libEnforcePointSeparator [disabled]="!expense.IsEditable" [(ngModel)]="rate.TtcOrigin"
                          [class.user-entered]="rate.IsEnteredByUser" [ngModelOptions]="{ standalone: true }"
                          value="TTC" class="form-control" (change)="UpdateTaxesWithTTC(i)" />
                        <span class="input-group-text" *ngIf="!rate.IsCoherent && nbOfIncoherentLines === 1">
                          <nb-icon [nbPopover]="'expense.tva-incoherency' | translate" nbPopoverTrigger="hint"
                            nbPopoverPlacement="bottom" icon="fa-exclamation-triangle"
                            class="text-warning small-icon"></nb-icon>
                        </span>
                      </div>
                    </td>
                    <td>
                      <button type="button" [disabled]="!expense.IsEditable" class="btn btn-danger"
                        (click)="deleteRate(i)">
                        <nb-icon icon="fa-times"></nb-icon>
                      </button>
                    </td>
                  </tr>
                  <tr *ngIf="expense.IsEditable">
                    <td colspan="10" class="text-center pb-0">
                      <button id="addTaxe" type="button" class="btn btn-primary"
                        (click)="addRate()">{{'expense.add-taxe' | translate}}</button>
                    </td>
                  </tr>
                </tbody>
                <!--Table body-->
              </table>
            </div>
          </div>
          <!-- </TABLE TAXES> -->
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item #dynamicFields *ngIf="expense.DynamicFieldsValues && expense.DynamicFieldsValues.length > 0"
        [expanded]="true">
        <nb-accordion-item-header>
          {{'expense.dynamic-fields' | translate}}
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row needs-validation" [class.was-validated]="isValidated">
            <div class="col-sm-6 form-group" *ngFor="let dynamicField of expense.DynamicFieldsValues">
              <label>{{ dynamicField.Field.Name }}
                <span *ngIf="dynamicField.Field.IsRequired">*</span></label>
              <input *ngIf="dynamicField.Field.Type == 'string'" [required]="dynamicField.Field.IsRequired"
                [disabled]="!expense.IsEditable" class="form-control" [(ngModel)]="dynamicField.Value"
                [ngModelOptions]="{ standalone: true }" />
              <select *ngIf="dynamicField.Field.Type == 'list'" [required]="dynamicField.Field.IsRequired"
                [disabled]="!expense.IsEditable" class="form-control" [(ngModel)]="dynamicField.Value"
                [ngModelOptions]="{ standalone: true }">
                <option value="" *ngIf="!dynamicField.Field.IsRequired"></option>
                <option [value]="val" *ngFor="let val of dynamicField.Field.PossibleValues">{{ val }}</option>
              </select>

              <div class="invalid-feedback">
                {{'expense.required' | translate}}
              </div>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>

    <!-- AMOUNT TO REIMBURSE -->
    <div class="row" *ngIf="report
              && report.ManagerId
              && (report.State.Code === 'VAL' || report.State.Code === 'SENT' || report.State.Code === 'ARCHIVED')
              && !expense.IsPerDiem">
      <div class="col form-group">
        <label>{{'expense.amount-to-reimbourse' | translate}}</label>
        <div class="input-group">
          <span class="input-group-text">
            <!-- <nb-icon icon="fa-euro-sign"></nb-icon> -->
            {{ connectedOnUser.Currency.Symbol }}
          </span>
          <input [disabled]="!expense.PaidWithPersonalAccount" type="number" step="0.01"
            [max]="expense.GlobalAmount.Ttc" name="amountToBeReimboursed" class="form-control"
            [(ngModel)]="expense.AmountToBeReimbursed" [ngModelOptions]="{ standalone: true }"
            [disabled]="report.State.Code === 'VAL' || report.State.Code === 'ARCHIVED' || report.ManagerId !== connectedOnUser.Id" />
        </div>
      </div>
    </div>
    <!-- REMAINING FOR THE USER-->
    <div class="row" *ngIf="report
         && report.ManagerId
         && (report.State.Code === 'VAL' || report.State.Code === 'SENT')
        && expense.PaidWithPersonalAccount
        && !expense.IsPerDiem">
      <hr>
      <div class="col d-flex total">
        <h5>
          {{'expense.remaining' | translate:
          {
          amount: ((expense.GlobalAmount.Ttc - expense.AmountToBeReimbursed) | number:'.2'),
          symbol: connectedOnUser.Currency.Symbol
          }
          }}
        </h5>
      </div>
    </div>

    <!-- <MANAGER COMMENTS> -->
    <div class="row" *ngIf="report && report.ManagerId
                            && (report.State.Code === 'VAL' || report.State.Code === 'SENT')">
      <div class="col form-group">
        <label>{{'expense.manager-comments' | translate}}</label>
        <textarea nbInput fullWidth [status]="(
              ( !expense.ValidationComment || (expense.ValidationComment.length === 0) )
              && rejectClicked
            ) ?
            'danger' : ''" placeholder="Champ obligatoire lorsque l'on rejette un reçu"
          [disabled]="report.ManagerId !== connectedOnUser.Id" rows="1" [(ngModel)]="expense.ValidationComment"
          [ngModelOptions]="{ standalone: true }"></textarea>
      </div>
    </div>
  </form>

  <div *ngIf="isNew || isExpenseOrSimpleUserSave()">
    <br />
    <div class="form-check" *ngIf="!isNew">
      <input class="form-check-input" type="checkbox" [(ngModel)]="shouldContinueAfterEditing" id="defaultCheck1" />
      {{'expense.update-next' | translate}}
      <br />
    </div>
    <button [disabled]="isLoadingTotal" class="btn btn-primary"
      *ngIf="expense.ExpenseType.Code !== 'MGE' || !needVehicule || vehicles?.length" (click)="Send()">
      {{'expense.valid-and-save' | translate}}
    </button>
  </div>
  <!-- <VALID/REJECT BUTTONS> -->
  <div *ngIf="isReportAndIsManagerValidation()">
    <div class="row">
      <div class="col-12">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" [(ngModel)]="shouldContinueAfterEditing"
            id="continueValidateAfterOk" />
          <label class="form-check-label" for="continueValidateAfterOk">{{'expense.update-next' | translate}}</label>
        </div>
      </div>
      <br />
      <div class="col-6 text-center">
        <button nbButton status="primary" class="margin-5" (click)="validationClick()">
          <nb-icon icon="fa-check" class="fa button-icon"></nb-icon> {{'expense.valid' | translate}}
        </button>
      </div>
      <div class="col-6 text-center">
        <button nbButton status="danger" class="margin-5" (click)="rejectionClick()">
          <nb-icon icon="fa-times" class="fa button-icon"></nb-icon> {{'expense.reject' | translate}}
        </button>
      </div>
    </div>
    <br />
  </div>
</ng-template>