import { EnforcePointSeparatorDirective } from './_directives/enforce-point-separator.directive';
import { RouterModule } from '@angular/router';
import { TwoColumnsDisplayComponent } from './_components/two-columns-display/two-columns-display.component';
import { RequiredFieldDisplayComponent } from './_components/required-field-display/required-field-display.component';
import { PageTitleComponent } from './_components/page-title/page-title.component';
import { LoadingComponent } from './_components/loading/loading.component';
import { FormDatePickerComponent } from './_components/form-date-picker/form-date-picker.component';
import { CurrencySelectComponent } from './_components/currency-select/currency-select.component';
import { CountrySelectComponent } from './_components/country-select/country-select.component';
import { ActionButtonsComponent } from './_components/action-buttons/action-buttons.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmptyListComponent } from './_components/empty-list/empty-list.component';
import { DialogComponent } from './_components/dialog/dialog.component';
import {
  NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSnotifireModule, SnotifireService, ToastDefaults } from 'ngx-snotifire';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// TO be used the Angular way <fa-icon>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTachometerAlt,
  faRoad,
  faUpload,
  faReceipt,
  faArchive,
  faUser,
  faBuilding,
  faQuestion,
  faBars,
  faList,
  faTh,
  faPlus,
  faEdit,
  faTrash,
  faFolder,
  faFolderOpen,
  faCamera,
  faEye,
  faEyeSlash,
  faCopy,
  faLink,
  faClock,
  faFlag,
  faCalendar,
  faEuroSign,
  faSync,
  faCheck,
  faTimes,
  faSignOutAlt,
  faCog,
  faCogs,
  faSave,
  faSearch,
  faUserTimes,
  faPaperPlane,
  faArrowsAlt,
  faMinus,
  faUndo,
  faRedo,
  faExclamationTriangle,
  faExclamationCircle,
  faShare,
  faInfoCircle,
  faFileSignature,
  faFileImage,
  faSignInAlt,
  faArrowLeft,
  faCalculator,
  faFileExport,
  faLockOpen,
  faLock,
  faLevelUpAlt,
  faUtensils,
  faHotel,
  faTaxi,
  faHandshake,
  faBus,
  faParking,
  faGasPump,
  faTools,
  faEllipsisH,
  faCarrot,
  faCarSide,
  faCar,
  faUniversity,
  faUsers,
  faMoon,
  faLaptop,
  faEnvelope,
  faTrain,
  faBook,
  faSyncAlt,
  faFrownOpen,
  faCommentAlt,
  faExclamation,
  faPrint,
  faCoffee,
  faInfo,
  faLightbulb,
  faChevronLeft,
  faChevronDown,
  faArrowRight,
  faMapMarkerAlt,
  faGlobeEurope
} from '@fortawesome/free-solid-svg-icons';

import {
  faFileAudio,
  faFileArchive,
  faFileExcel,
  faFile,
  faCreditCard,
  faSmile,
  faFilePdf
} from '@fortawesome/free-regular-svg-icons';
import { NbDialogModule, NbTooltipModule, NbIconModule } from '@nebular/theme';

import {
  faFacebookF,
  faYoutube,
  faTwitter,
  faRedditAlien,
  faLinkedinIn,
  faGooglePlusG,
  faTumblr,
  faPinterestP,
  faWhatsapp,
  faVk,
  faFacebookMessenger,
  faTelegramPlane,
  faMix,
  faXing,
  faLine,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faGooglePlusG,
  faYoutube,
  faPinterestP,
  faRedditAlien,
  faTumblr,
  faWhatsapp,
  faVk,
  faFacebookMessenger,
  faTelegramPlane,
  faMix,
  faXing,
  faCommentAlt,
  faLine,
  faEnvelope,
  faCheck,
  faPrint,
  faLink,
  faEllipsisH,
  faMinus,
  faLightbulb,
  faCoffee,
  faInfo,
  faTachometerAlt,
  faUpload,
  faRoad,
  faReceipt,
  faArchive,
  faUser,
  faBuilding,
  faQuestion,
  faBars,
  faList,
  faTh,
  faPlus,
  faEdit,
  faTrash,
  faFolder,
  faFolderOpen,
  faCamera,
  faEye,
  faEyeSlash,
  faCopy,
  faLink,
  faClock,
  faFrownOpen,
  faFlag,
  faCalendar,
  faEuroSign,
  faSync,
  faCheck,
  faTimes,
  faSignInAlt,
  faSignOutAlt,
  faCog,
  faCogs,
  faSave,
  faSearch,
  faUserTimes,
  faPaperPlane,
  faArrowsAlt,
  faMinus,
  faUndo,
  faRedo,
  faFileArchive,
  faFileExcel,
  faFilePdf,
  faFile,
  faCreditCard,
  faExclamationTriangle,
  faExclamationCircle,
  faSmile,
  faShare,
  faInfoCircle,
  faFileSignature,
  faFileImage,
  faArrowLeft,
  faCalculator,
  faFileExport,
  faLock,
  faLockOpen,
  faLevelUpAlt,
  faUtensils,
  faHotel,
  faTaxi,
  faHandshake,
  faBus,
  faParking,
  faGasPump,
  faTools,
  faEllipsisH,
  faCarrot,
  faCar,
  faCarSide,
  faUniversity,
  faUsers,
  faMoon,
  faLaptop,
  faEnvelope,
  faTrain,
  faBook,
  faSyncAlt,
  faChevronLeft,
  faChevronDown,
  faArrowRight,
  faMapMarkerAlt,
  faGlobeEurope,
);

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        NgbModule,
        NgSelectModule,
        NgxSnotifireModule,
        NbTooltipModule,
        NbIconModule,
    ],
    declarations: [
        ActionButtonsComponent,
        CountrySelectComponent,
        CurrencySelectComponent,
        EmptyListComponent,
        FormDatePickerComponent,
        LoadingComponent,
        PageTitleComponent,
        RequiredFieldDisplayComponent,
        TwoColumnsDisplayComponent,
        DialogComponent,
        EnforcePointSeparatorDirective,
    ],
    exports: [
        NgbModule,
        FontAwesomeModule,
        NbTooltipModule,
        RouterModule,
        NgSelectModule,
        ActionButtonsComponent,
        CountrySelectComponent,
        CurrencySelectComponent,
        EmptyListComponent,
        FormDatePickerComponent,
        LoadingComponent,
        PageTitleComponent,
        RequiredFieldDisplayComponent,
        TwoColumnsDisplayComponent,
        DialogComponent,
        EnforcePointSeparatorDirective,
    ],
    providers: [
        { provide: 'snotifireConfig', useValue: ToastDefaults },
        SnotifireService,
    ]
})
export class OnCommonModule { }
