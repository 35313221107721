<div class="card shadow" *ngIf="subscription && !isLoading">
    <h4 class="subscription-title">{{'payment.stripe-sub-summary-card.subscription' | translate}}
        {{subscription.RecurringPrice.Product.Name}}<br>
        <span class="licenses">{{subscription.Quantity}}
            {{'payment.stripe-sub-summary-card.licenses' | translate}}</span>
    </h4><br>
    <span *ngIf="subscription.NextRenewalDate">{{'payment.stripe-sub-summary-card.auto-renew' | translate}}:
        {{subscription.NextRenewalDate | date}}</span>
    <br>
    <span>{{'payment.stripe-sub-summary-card.total-price' | translate}}:
        <strong>{{totalPrice | number:'1.2-2'}}CHF</strong>
        {{'payment.stripe-sub-summary-card.every' | translate}}
        {{subscription.RecurringPrice.IntervalCount != 1 ? subscription.RecurringPrice.IntervalCount: ''}}
        {{subscription.RecurringPrice.Interval | translate}}</span>
    <br>
</div>

<on-loading *ngIf="isLoading"></on-loading>