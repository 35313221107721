<on-two-columns-display [title]="'company.accountant-export.code-title' | translate">
  <div description>
    <p>{{'company.accountant-export.code-description' | translate}}</p>
  </div>

  <div content class="col-12 form-horizontal">
    <on-loading *ngIf="isLoadingAccountingCodes"></on-loading>
    <div class="row" *ngIf="!isLoadingAccountingCodes">
      <div class="form-group col-6 row"
        *ngFor="let acCode of accountingCodes">
        <label class="col-12 col-form-label d-flex justify-content-start"
          for="type"><b>{{ acCode.ExpenseTypeName }}</b></label>
        <div class="col-12">
          <input class="form-control" type="text" [(ngModel)]="acCode.Code" />
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="text-center">
        <button class="btn btn-primary" (click)="saveAccountingCodes()">{{'company.accountant-export.code-save' |
          translate}}</button>
      </div>
    </div>
  </div>
</on-two-columns-display>


<on-two-columns-display [title]="'company.accountant-export.vatcode-title' | translate">
  <div description>
    <p>{{'company.accountant-export.vatcode-description' | translate}}</p>
  </div>

  <div content class="col-12 form-horizontal">
    <on-loading *ngIf="isLoadingVatCharges"></on-loading>
    <div class="row" *ngIf="!isLoadingVatCharges">
      <div class="form-group">
        <label><strong>{{'company.accountant-export.counterpart-acount' | translate}}</strong></label>
        <input class="form-control" [(ngModel)]="ttcChargeAccount.Code" />
      </div>
      <div class="m-1 mt-3">
        <table class="table table-bordered">
          <tr>
            <th width="200px">{{'company.accountant-export.vat-percent-th' | translate}}</th>
            <th>{{'company.accountant-export.vat-code-th' | translate}}</th>
            <th></th>
          </tr>
          <tr *ngFor="let charge of vatChargeAccounts">
            <td>
              <div class="input-group">
                <input class="form-control" type="text" [(ngModel)]="charge.Vat" />
                <span class="input-group-text">%</span>
              </div>
            </td>
            <td>
              <div class="input-group">
                <input class="form-control" type="text" [(ngModel)]="charge.Code" />
              </div>
            </td>
            <td>
              <div>
                <button class="btn btn-sm btn-danger" (click)="deleteVatCharge(charge)">
                  <nb-icon icon="fa-trash"></nb-icon>
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <button class="btn btn-info" (click)="addVatCharge()">
          <nb-icon icon="fa-plus"></nb-icon> {{'company.accountant-export.vat-code-add' | translate}}
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="text-center">
        <button class="btn btn-primary" style="margin-top: 30px;"
          (click)="saveVatChargeAccount()">{{'company.accountant-export.vat-code-save' | translate}}</button>
      </div>
    </div>
  </div>
</on-two-columns-display>